import { useMe } from '@/components/AuthenticationManager'
import { ClearForm } from '@/components/ClearForm/ClearForm'
import {
  PaymentCreateForm,
  usePaymentCreateForm,
} from '@/components/Forms/PaymentCreate/PaymentCreateForm'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { CopyLinkModal } from '@/components/Modals/CopyLinkModal/CopyLinkModal'
import { PaymentContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/types'
import { usePaymentCreationContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/PaymentCreationContext'
import { useCreatePaymentMethods } from '@/components/Pages/Home/usePaymentMethods'
import { Requirements } from '@/components/Requirements/Requirements'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { ActiveTags, hasFeatureTag } from '@/types'
import { scrollUp } from '@/utils/scrollUp'
import React, { useCallback, useEffect, VoidFunctionComponent } from 'react'
import { DeepPartial, FormProvider } from 'react-hook-form'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { StopCashInBanner } from '@/components/Pages/Home/StopCashInBanner/StopCashInBanner'

interface Props extends TrackerProps {
  initialFormValues: DeepPartial<PaymentCreateFormValues>
  defaultFormValues: DeepPartial<PaymentCreateFormValues>
}

export const CreatePayment: VoidFunctionComponent<Props> = ({
  track,
  initialFormValues,
  defaultFormValues,
}) => {
  const me = useMe()

  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const paymentMethodsHandler = useCreatePaymentMethods(track)
  const { clearPayment, createdPayment, isLinkAvailable } = paymentMethodsHandler

  const form = usePaymentCreateForm(defaultFormValues)

  const { setPaymentCreationContext } = usePaymentCreationContext()

  useEffect(() => {
    if (initialFormValues !== defaultFormValues) {
      form.reset(initialFormValues, { keepDefaultValues: true })
    }
  }, [defaultFormValues, form, initialFormValues])

  const resetForm = useCallback(() => {
    form.reset(defaultFormValues)
    clearPayment()
    scrollUp()
    track('clear_form')
    setPaymentCreationContext(PaymentContext.newPayment)
  }, [clearPayment, form, defaultFormValues, track, setPaymentCreationContext])

  const closeDetailPaymentModal = () => {
    navigate('/', { replace: true })
  }

  const closeCopyLinkModal = useCallback(() => {
    form.reset(defaultFormValues)
    clearPayment()
    setPaymentCreationContext(PaymentContext.newPayment)
  }, [clearPayment, form, defaultFormValues, setPaymentCreationContext])

  useEffect(() => {
    if (isLinkAvailable && !createdPayment) {
      setSearchParams('', { replace: true })
    }
  }, [createdPayment, isLinkAvailable, setSearchParams])

  const canClearForm = form.formState.isDirty

  return (
    <>
      <FormProvider {...form}>
        {!me.merchant.can_pay_in && <StopCashInBanner track={track} />}
        <PaymentCreateForm
          track={track}
          forms={<ClearForm disabled={!canClearForm} onClick={resetForm} />}
          methods={hasFeatureTag(ActiveTags.fastMode, me) && <Requirements track={track} />}
          paymentMethodsHandler={paymentMethodsHandler}
        />
        <CopyLinkModal
          isOpen={isLinkAvailable}
          onClose={closeCopyLinkModal}
          link={createdPayment?.url ?? ''}
          paymentId={createdPayment?.id ?? ''}
        />
      </FormProvider>
      <Outlet context={[closeDetailPaymentModal]} />
    </>
  )
}
