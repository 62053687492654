import { useEmbedded } from '@/apis/embedded.context'
import { useMe } from '@/components/AuthenticationManager'
import { BurgerButton } from '@/components/BurgerButton'
import { Menu } from '@/components/Menu'
import { MerchantSelector } from '@/components/MerchantSelector'
import s from '@/components/PageHeader/PageHeader.module.css'
import { useTracking } from '@/thirdParties/analytics'
import { ActiveTags, hasFeatureTag } from '@/types'
import { useGoBack } from '@/utils/useGoBack'
import { Button, Icon, IconName } from '@alma/react-components'
import cx from 'classnames'
import React, { useCallback, useEffect, VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

export const HomeHeader: VoidFunctionComponent = () => {
  const me = useMe()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const goBack = useGoBack('/')
  const { embedded } = useEmbedded()

  const isMenuOpen = searchParams.has('menu')

  const track = useTracking('menu', isMenuOpen)

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isMenuOpen])

  const closeMenu = useCallback(() => {
    track('menu_close')
    goBack()
  }, [goBack, track])

  return (
    <>
      <div className={cx(s.header, { [s.loginAs]: me.real_user })} data-testid="home-header">
        {!embedded && (
          <BurgerButton
            onClick={() => {
              track('menu_open')
              setSearchParams(new URLSearchParams('menu'))
            }}
          />
        )}
        <div className={s.merchantSelector}>
          <MerchantSelector data-testid="merchant-selector-header" track={track} />
        </div>
        {!embedded && (
          <div>
            {!hasFeatureTag(ActiveTags.cantExitFinalize, me) && (
              <>
                <Button
                  className={s.paymentPageButtonDesktop}
                  data-stonly-anchor="paymentListNavButton"
                  data-testid="payments-followup-nav-button"
                  onClick={() => navigate('/payments')}
                  role="link"
                  color="secondary"
                  size="small"
                  icon={IconName.money}
                >
                  <FormattedMessage
                    id="page.header.consultPayments"
                    defaultMessage="Consult payments"
                    description="This is the button on the top right corner on the payment form to access the past payment list."
                  />
                </Button>
                <Link to="/payments" className={s.paymentPageButtonMobile}>
                  <Icon icon="money" />
                </Link>
              </>
            )}
          </div>
        )}
      </div>
      <div className={cx(s.menuContainer, { [s.isOpen]: isMenuOpen })} data-testid="menu-container">
        <Menu onCloseMenu={closeMenu} track={track} />
      </div>
      <div
        tabIndex={0}
        role="button"
        aria-label="Close menu"
        onKeyPress={closeMenu}
        className={cx(s.backdrop, { [s.isOpen]: isMenuOpen })}
        onClick={closeMenu}
        data-testid="background"
      />
    </>
  )
}
