import { PaymentContext } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/types'
import React, {
  FunctionComponent,
  useMemo,
  useState,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react'

/* For log/tracking reasons, we want to know the context of the payment's creation
 * It can be :
 * - A payment created from scratch by filling the form
 * - A payment that has been restarted
 * - A payment that has been pre-filled from URL Params
 * - A payment that has been pre-filled from simulated payment (self-served checkout)
 */

type PaymentCreationContextType = {
  paymentCreationContext: PaymentContext
  setPaymentCreationContext: (context: PaymentContext) => void
}

const initialPaymentContext = {
  paymentCreationContext: PaymentContext.newPayment,
  setPaymentCreationContext: () => {
    throw new Error('setPaymentCreationContext is not ready.')
  },
}

const PaymentCreationContext = createContext<PaymentCreationContextType>(initialPaymentContext)

export const usePaymentCreationContext = (): PaymentCreationContextType =>
  useContext(PaymentCreationContext)

export const PaymentCreationContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [paymentCreationContext, setPaymentCreationContext] = useState(PaymentContext.newPayment)

  const contextValue = useMemo(
    () => ({
      paymentCreationContext,
      setPaymentCreationContext,
    }),
    [paymentCreationContext, setPaymentCreationContext]
  )

  return (
    <PaymentCreationContext.Provider value={contextValue}>
      {children}
    </PaymentCreationContext.Provider>
  )
}
