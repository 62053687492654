import { RequirementsTableProps } from '@/components/RequirementsTable/props'
import s from '@/components/RequirementsTable/RequirementsTable.module.css'
import cx from 'classnames'
import React, { ReactNode, VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const RequirementsTableSm: VoidFunctionComponent<RequirementsTableProps> = () => (
  <div className={cx(s.container, s.mobile)} data-testid="requirements-table-small">
    <div className={s.row}>
      <div className={cx(s.cell, s.cellHeader)}>
        <FormattedMessage
          id="home.requirements.table.standard.title"
          defaultMessage="We've got time"
          description="Requirements description table: standard mode column header"
        />
        <br />
        <span>
          <FormattedMessage
            id="home.requirements.table.standard.subtitle"
            defaultMessage="Standard journey"
            description="Requirements description table: standard mode column sub-header"
          />
        </span>
      </div>
      <div className={s.cell}>
        <FormattedMessage
          id="home.requirements.table.duration"
          defaultMessage="Average duration"
          description="Requirements description table: duration row header"
        />
        <br />
        <FormattedMessage
          id="home.requirements.table.standard.duration"
          defaultMessage="6 minutes"
          description="Requirements description table: duration for standard mode"
        />
      </div>
      <div className={s.cell}>
        <FormattedMessage
          id="home.requirements.table.successRate"
          defaultMessage="Success rate (payment acceptation)"
          description="Requirements description table: success rate row header"
        />
        <br />
        <strong>
          <FormattedMessage
            id="home.requirements.table.standard.successRate"
            defaultMessage="95%"
            description="Requirements description table: success rate for standard mode"
          />
        </strong>
      </div>
      <div className={s.cell}>
        <FormattedMessage
          id="home.requirements.table.requirements"
          defaultMessage="Potential requirements"
          description="Requirements description table: requirements row header"
        />
        <br />
        <strong>
          <FormattedMessage
            id="home.requirements.table.standard.requirements"
            defaultMessage="Phone {newLine} Declarative resources check (3DS) {newLine} Identity and/or address {newLine} Incomes"
            values={{
              newLine: <br />,
            }}
            description="Requirements description table: list of requirements for standard mode"
          />
        </strong>
      </div>
      <div className={s.cell}>
        <FormattedMessage
          id="home.requirements.table.benefits"
          defaultMessage="Benefits"
          description="Requirements description table: benefits row header"
        />
        <br />
        <FormattedMessage
          id="home.requirements.table.standard.benefits"
          defaultMessage="The detailed analysis of your client's information ensures that <b>we can better identify</b> the risk of default."
          values={{
            b: (text: ReactNode) => <strong>{text}</strong>,
          }}
          description="Requirements description table: benefits for standard mode (bold text inside <b> tags)"
        />
      </div>
    </div>
    <div className={s.row}>
      <div className={cx(s.cell, s.cellHeader)}>
        <FormattedMessage
          id="home.requirements.table.fast.title"
          defaultMessage="We're in a hurry"
          description="Requirements description table: fast mode column header"
        />
        <br />
        <span>
          <FormattedMessage
            id="home.requirements.table.fast.subtitle"
            defaultMessage="Fast journey"
            description="Requirements description table: fast mode column sub-header"
          />
        </span>
      </div>
      <div className={s.cell}>
        <FormattedMessage
          id="home.requirements.table.duration"
          defaultMessage="Average duration"
          description="Requirements description table: duration row header"
        />
        <br />
        <strong>
          <FormattedMessage
            id="home.requirements.table.fast.duration"
            defaultMessage="2 minutes"
            description="Requirements description table: duration for fast mode"
          />
        </strong>
      </div>
      <div className={s.cell}>
        <FormattedMessage
          id="home.requirements.table.successRate"
          defaultMessage="Success rate (payment acceptation)"
          description="Requirements description table: success rate row header"
        />
        <br />
        <FormattedMessage
          id="home.requirements.table.fast.successRate"
          defaultMessage="75%"
          description="Requirements description table: success rate for fast mode"
        />
      </div>
      <div className={s.cell}>
        <FormattedMessage
          id="home.requirements.table.requirements"
          defaultMessage="Potential requirements"
          description="Requirements description table: requirements row header"
        />
        <br />
        <strong>
          <FormattedMessage
            id="home.requirements.table.fast.requirements"
            defaultMessage="Phone {newLine} Declarative resources check (3DS)"
            values={{
              newLine: <br />,
            }}
            description="Requirements description table: list of requirements for fast mode"
          />
        </strong>
      </div>
      <div className={s.cell}>
        <FormattedMessage
          id="home.requirements.table.benefits"
          defaultMessage="Benefits"
          description="Requirements description table: benefits row header"
        />
        <br />
        <FormattedMessage
          id="home.requirements.table.fast.benefits"
          defaultMessage="<b>Save time</b> at the checkout with our quick analysis."
          values={{
            b: (text: ReactNode) => <strong>{text}</strong>,
          }}
          description="Requirements description table: benefits for fast mode (bold text inside <b> tags)"
        />
      </div>
    </div>
  </div>
)
