import { useMe } from '@/components/AuthenticationManager'
import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import {
  ButtonsAction,
  GreyZone,
  Header,
  ItDoesNotWorkCollapsible,
  List,
  ListItem,
} from '@/components/PaymentsModal/Templates'
import { HorizontalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import { hasFeatureTag, ActiveTags } from '@/types'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const PaymentValidation: VoidFunctionComponent<StepProps> = ({
  amountAndPlan,
  customerName,
  createdDate,
  updatedDate,
  ActionButtons,
}) => {
  const intl = useIntl()
  const me = useMe()
  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />
      <GreyZone
        progressBarTitle={intl.formatMessage({
          id: 'payment.step.detail.progress.title.verifications',
          defaultMessage: 'Verifications',
          description:
            'Title of the progress bar in the payment detail modal for the verifications steps',
        })}
        progress={40}
      >
        <HorizontalTitle updated={updatedDate}>
          <FormattedMessage
            id="payment.detail.requirementStep.payment_validation"
            defaultMessage="Bank authorization"
            description="Title of the payment requirement step in which we get the banks authorization for the installments"
          />
        </HorizontalTitle>
        <List>
          <ListItem>
            <FormattedMessage
              id="payment.detail.payment_validation.validation"
              defaultMessage="Your customer must validate the transaction on their banking application (password or SMS) and then return to the payment page."
              description="Customer help for the payment requirement step in which we get the banks authorization for the installments"
            />
          </ListItem>
        </List>
      </GreyZone>
      <ItDoesNotWorkCollapsible>
        <div>
          <FormattedMessage
            id="payment.detail.payment_validation.it_does_not_work"
            defaultMessage="If your customer does not receive the text message, invite him/her to move to a different location to better reach the network. When the transaction is validated, your customer must return to the Alma payment page."
            description="This message should help merchant to give advice to their customer when he can't validate 3ds step"
          />
        </div>
        <ButtonsAction>
          {!hasFeatureTag(ActiveTags.cantExitFinalize, me) && (
            <ActionButtons.Restart color="link" />
          )}
          <ActionButtons.Cancel color="link" />
        </ButtonsAction>
      </ItDoesNotWorkCollapsible>
    </>
  )
}
