/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { FullAddress, readMapboxFeature } from '@/apis/geocoding'
import { useSearchAddressQuery } from '@/apis/hooks'
import { MapboxPlaceType } from '@/types/Mapbox'
import { useThrottle } from '@/utils/throttleDebounceHooks'
import cx from 'classnames'
import { useCombobox } from 'downshift'
import React, { FC, useEffect, useMemo, useRef } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  ADDRESS_NOT_IN_LIST_CASE,
  PaymentCreateFormValues,
} from '@/components/Forms/PaymentCreate/types'
import { Field, Input } from '@alma/react-components'
import s from '@/components/Forms/PaymentCreate/CustomerInfoForm/AddressSelector.module.css'
import { useDisableField } from '@/components/Forms/disabledFields'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'

type Props = {
  customerInfoRequired: { value: boolean; message: string } | undefined
} & TrackerProps

export const AddressSelector: FC<Props> = ({ customerInfoRequired, track }) => {
  const intl = useIntl()
  const { register, watch, setValue, trigger } = useFormContext<PaymentCreateFormValues>()
  const { errors } = useFormState<PaymentCreateFormValues>()
  const isValidAddressRef = useRef(false)
  const addressSearch = watch('customer.addressAutocompleteFieldValue')
  const searchThrottle = useThrottle((addressSearch || '').trim())

  const registerProps = register('customer.addressAutocompleteFieldValue', {
    required: customerInfoRequired,
    validate: () => {
      if (!customerInfoRequired) {
        return true
      }

      return isValidAddressRef.current ? true : 'This field is required'
    },
  })

  const searchResult = useSearchAddressQuery(searchThrottle, {
    onError: async () => {
      setValue('customer.addressAutocomplete', ADDRESS_NOT_IN_LIST_CASE)
      track('address_autocomplete_mapbox_error')
    },
  })

  const addressNotInListLabel = intl.formatMessage({
    id: 'address.not.in.list.option',
    defaultMessage: 'Enter the address manually',
    description:
      'This is an option in the autocomplete address suggestion to allow the user to fill the address manually if they can not find it in the provided suggestion list',
  })

  const addressSuggestions = useMemo(
    () =>
      searchResult.data
        ? searchResult.data.features
            .filter((feature) => feature.place_type[0] === MapboxPlaceType.address)
            .map<FullAddress | typeof ADDRESS_NOT_IN_LIST_CASE>(readMapboxFeature)
            .concat(ADDRESS_NOT_IN_LIST_CASE)
        : // We want to display the option to fill address manually even if there is no searchResult.data
          [ADDRESS_NOT_IN_LIST_CASE as typeof ADDRESS_NOT_IN_LIST_CASE],
    [searchResult.data]
  )

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
  } = useCombobox<FullAddress | typeof ADDRESS_NOT_IN_LIST_CASE>({
    id: 'customer.addressAutocompleteFieldValue',
    onInputValueChange(change) {
      const selectedLabel =
        change.selectedItem === ADDRESS_NOT_IN_LIST_CASE ? '' : change.selectedItem?.label

      isValidAddressRef.current = !!change.inputValue && change.inputValue === selectedLabel
      void trigger('customer.addressAutocompleteFieldValue')
    },
    onSelectedItemChange(change) {
      setValue('customer.addressAutocomplete', change.selectedItem)

      if (change.selectedItem === ADDRESS_NOT_IN_LIST_CASE) {
        track('address_autocomplete_manual')
        setValue('customer.addressManual.address', addressSearch)
      }

      track('address_autocomplete_use')
    },
    items: addressSuggestions,
    itemToString(item) {
      return item !== ADDRESS_NOT_IN_LIST_CASE && item !== null ? item.label : ''
    },
  })

  useEffect(() => {
    // Sync downshift with react-hook-form when using form.reset()
    if (addressSearch === '') {
      setInputValue('')
    }
  }, [addressSearch, setInputValue])

  const labelProps = getLabelProps()
  const inputProps = getInputProps(registerProps)
  const menuProps = getMenuProps()
  const { disableField } = useDisableField<PaymentCreateFormValues>()

  // react-component can't put the id on the label element, so labelledby would be invalid.
  delete inputProps['aria-labelledby']
  delete menuProps['aria-labelledby']

  return (
    <Field
      label={
        <FormattedMessage
          id="customer.info.form.address"
          defaultMessage="Address"
          description="Field label in the customer details section of POS page. Mandatory field (if not filled-in, an error message appears in red below the field)."
        />
      }
      id={labelProps.id}
      htmlFor={labelProps.htmlFor}
      error={errors.customer?.addressAutocompleteFieldValue?.message}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'customer.info.form.addressAutocomplete.placeholder',
          defaultMessage: 'Type to search address',
          description:
            'Placeholder within the field "Address autocomplete", in the customer details section of POS page. Used as an indication to help understanding the search behavior in the field. It disappears when the user starts to type in.',
        })}
        {...inputProps}
        onChangeText={() => null}
        disabled={disableField('customer.addressAutocomplete')}
        error={!!errors.customer?.addressAutocompleteFieldValue?.message}
      />

      <div className={s.menuContainer}>
        <ul className={cx(s.menu, { [s.menuHidden]: !isOpen })} {...menuProps}>
          {isOpen &&
            addressSuggestions.map((item, index) => (
              <li
                className={cx(s.item, highlightedIndex === index && s.itemHighlighted)}
                key={index}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                {item === ADDRESS_NOT_IN_LIST_CASE ? addressNotInListLabel : item.label}
              </li>
            ))}
        </ul>
      </div>
    </Field>
  )
}
