import { useMe } from '@/components/AuthenticationManager'
import { OfferFeesForm } from '@/components/Forms/PaymentCreate'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { Installment } from '@/components/Installments/Installment'
import s from '@/components/Installments/Installments.module.css'
import {
  InstallmentsTotal,
  InstallmentsTotalFees,
  InstallmentsTotalInterest,
} from '@/components/Installments/InstallmentsTotal'
import { ActiveTags, Eligibility, hasFeatureTag, isEligibilityValidatedCredit } from '@/types'
import { centsToEuros } from '@/utils/prices'
import { CardFooter } from '@alma/react-components'
import cx from 'classnames'
import React, { useMemo, FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

type Props = {
  eligibility?: Eligibility
}
export const InstallmentsContent: FC<Props> = ({ eligibility }) => {
  const me = useMe()
  const { watch } = useFormContext<PaymentCreateFormValues>()
  const offerFees = Boolean(me.can_disable_customer_fees && watch('offerFees.offered'))

  // FIX ME : get total from server side ?
  const totalCalculated = useMemo(
    () =>
      eligibility?.eligible
        ? eligibility.payment_plan?.reduce(
            (total, installment) =>
              total + (offerFees ? installment.purchase_amount : installment.total_amount),
            0
          )
        : 0,
    [eligibility, offerFees]
  )

  if (!eligibility) {
    // use same message than installment to avoid UI jump
    return (
      <div className={s.creditInfo} data-testid="errorNoAmount">
        <FormattedMessage
          id="installments.error.no-amount"
          defaultMessage="Enter a payment amount."
          description="This is a message in the installment plan summary, it is displayed when the seller did not input an amount"
        />
      </div>
    )
  }

  if (!eligibility?.eligible) {
    return (
      <div className={s.creditInfo} data-testid="errorInvalidPaymentFee">
        <FormattedMessage
          id="installments.error.invalid-payment-fee"
          defaultMessage="Invalid payment amount with number of installments."
          description="This is a message in the installement plan summary, it is displayed when the seller input an amount that is not authorised with the chosen feeplan option"
        />
      </div>
    )
  }

  if (isEligibilityValidatedCredit(eligibility) && !hasFeatureTag(ActiveTags.iobsp, me)) {
    return (
      <div className={s.creditInfo} data-testid="errorIsCredit">
        <FormattedMessage
          id="installments.error.credit"
          defaultMessage="Your customer's payment schedule will be available on their payment page."
          description="On credit feeplans (over 4 installments), we can't display the installment plan information to the seller because of our credit indicator status (and not IOBSP). In this case, the end customer will see the information once he entered the checkout process."
        />
      </div>
    )
  }

  const isPaymentPlanHuge = eligibility.payment_plan.length > 5

  return (
    <>
      <div
        className={cx('installmentList', s.installments, {
          [s.shortenHugePlan]: isPaymentPlanHuge,
        })}
      >
        {eligibility.payment_plan.map((installment, index) => (
          <Installment
            key={`${installment.due_date}-${installment.customer_fee}-${installment.purchase_amount}`}
            installment={installment}
            isFirstInstallment={index === 0}
            offerFees={offerFees}
            p1x={eligibility.payment_plan.length === 1}
            me={me}
          />
        ))}
      </div>
      <div className={s.footerCard}>
        <CardFooter className={s.footer}>
          <InstallmentsTotal totalInEuros={centsToEuros(totalCalculated)} />
          {isEligibilityValidatedCredit(eligibility) ? (
            <InstallmentsTotalInterest
              interestInEuros={centsToEuros(eligibility.customer_interest)}
              annualInterestRateInPercent={eligibility.annual_interest_rate / 100}
            />
          ) : (
            <InstallmentsTotalFees
              feesInEuros={centsToEuros(eligibility.customer_fee)}
              offerFees={offerFees}
            />
          )}
        </CardFooter>
        <OfferFeesForm display={me.can_disable_customer_fees && eligibility.customer_fee > 0} />
      </div>
    </>
  )
}
