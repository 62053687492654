import { Eligibility } from '@/types/Eligibility'
import { BillingAddress } from '@/types/Payment'
import { AxiosInstance } from 'axios'

export type EligibilityQueries = {
  deferred_days?: number
  deferred_months?: number
  installments_count: number
  deferred_trigger?: boolean
}
export interface EligibilityRequest {
  purchase_amount: number
  queries?: EligibilityQueries[]
  origin?: string
  billing_address?: BillingAddress
  shipping_address?: BillingAddress
}

export const eligibilityCheckGenerator =
  (client: AxiosInstance) =>
  async (eligibilityData: EligibilityRequest, merchantId?: string, signal?: AbortSignal) => {
    const { data } = await client.post<Eligibility[]>('v2/payments/eligibility', eligibilityData, {
      signal,
      // If merchant ID is defined, we want to add the authorization header Alma-Merchant-Auth <merchantId> to the request.
      // this will be useful for eligibility caching enablement as cache is based on merchant ids.
      // The case where merchant id is not defined should never happen, but if it does, the request might be blocked, and we want to
      // avoid any regression.
      headers: {
        ...(merchantId && { Authorization: `Alma-Merchant-Auth ${merchantId}` }),
      },
    })
    return data
  }
