import { AxiosInstance } from 'axios'

export type PostContextType = {
  murmur: string
  origin: string
}

export const postContextGenerator = (client: AxiosInstance) => async (context: PostContextType) => {
  await client.post<void>('v1/context', {
    context: window.btoa(JSON.stringify(context)),
  })
}
