import { Header, Modal } from '@alma/react-components'
import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import s from '@/components/Modals/ActionsModal/ActionsModal.module.css'

interface ActionsModalProps {
  isOpen: boolean
  onClose: () => void
  header: ReactNode
  actions: ReactNode
}

export const ActionsModal: FunctionComponent<PropsWithChildren<ActionsModalProps>> = ({
  isOpen,
  onClose,
  header,
  actions,
  children,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    shouldCloseOnOverlayClick={false}
    contentClassName={s.actionsModal}
  >
    <div className={s.content}>
      <Header level={6} as="h2">
        {header}
      </Header>
      {children && <div>{children}</div>}
    </div>
    <div className={s.actions}>{actions}</div>
  </Modal>
)
