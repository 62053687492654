import { PaymentListFormValues, PaymentPeriodFilter } from '@/components/Forms/PaymentList/types'
import { usePaymentFollowupContext } from '@/components/Pages'
import { Pagination } from '@/components/Pagination'
import { PaymentCard } from '@/components/PaymentCard'
import s from '@/components/PaymentList/PaymentList.module.css'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import cx from 'classnames'
import { subMonths, subWeeks, subYears } from 'date-fns'
import React, { useEffect, useMemo, VoidFunctionComponent } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

interface Props extends TrackerProps {
  className?: string
}

const getMidnightUTCDate = (date: Date) =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))

export const startDateFor: {
  [key in PaymentPeriodFilter]: () => Date
} = {
  day: () => getMidnightUTCDate(new Date()),
  week: () => getMidnightUTCDate(subWeeks(new Date(), 1)),
  month: () => getMidnightUTCDate(subMonths(new Date(), 1)),
  year: () => getMidnightUTCDate(subYears(new Date(), 1)),
}

export const PAYMENTS_PER_PAGE = 8

export const PaymentList: VoidFunctionComponent<Props> = ({ className, track }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = parseInt(searchParams.get('page') ?? '', 10) - 1

  const { filteredPayments } = usePaymentFollowupContext()
  /** Calculate payments on current page */
  const currentPaymentsToDisplay = useMemo(
    () =>
      filteredPayments.slice(
        currentPage * PAYMENTS_PER_PAGE,
        (currentPage + 1) * PAYMENTS_PER_PAGE
      ),
    [currentPage, filteredPayments]
  )

  /** Calculate total number of pages */
  const nbPages = useMemo(
    () => Math.ceil(filteredPayments.length / PAYMENTS_PER_PAGE),
    [filteredPayments.length]
  )

  const { watch } = useFormContext<PaymentListFormValues>()
  const [period, status, name] = watch(['period', 'status', 'name'])
  /** Reset pagination when filtering changed */
  useEffect(() => {
    if (Number.isNaN(currentPage) || currentPage > nbPages - 1 || currentPage < 0) {
      searchParams.set('page', '1')
      setSearchParams(searchParams, { replace: true })
    }
  }, [currentPage, nbPages, setSearchParams, period, status, name, searchParams])

  return (
    <div
      className={cx(className, s.container, { [s.errorMessage]: filteredPayments.length === 0 })}
      data-testid="payment-list"
    >
      {filteredPayments.length ? (
        <>
          <FormattedMessage
            id="payment.list.count"
            defaultMessage="{count, plural, one {# result} other {# results}}"
            description="This is the number of past payments currently displayed in the past payment list. This depends on the filters input, if you're focusing on a specific customer, a specific timeframe, a specific step or not"
            values={{ count: filteredPayments.length }}
          />
          <div className={s.paymentsPaginatedList}>
            {currentPaymentsToDisplay.map((payment) => (
              <PaymentCard key={payment.id} payment={payment} track={track} />
            ))}
            <div className={s.paymentsPagination}>
              <Pagination
                nbPages={nbPages}
                onChange={(page) =>
                  setSearchParams(new URLSearchParams({ page: `${page.selected + 1}` }), {
                    replace: true,
                  })
                }
                activePage={currentPage <= nbPages - 1 ? currentPage : 0}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={s.noPayments}>
          <FormattedMessage
            id="payment.list.no.payments"
            defaultMessage="No payments were found with the applied search criteria, please try a different search"
            description="This is the message displayed in the payment list when no results were found according to the filters set"
          />
        </div>
      )}
    </div>
  )
}
