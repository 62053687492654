/* istanbul ignore file: Tests can only be executed in 'test' env, so coverage on the setup is irrelevant */
import { AccessChecker } from '@/accessChecker'
import { AdyenGatewayProvider } from '@/apis/adyenGateway'
import { EmbeddedProvider } from '@/apis/embedded.context'
import { GlobalErrorBoundary, TranslatedErrorBoundary } from '@/components/AppErrorPage'
import {
  AuthenticationManager,
  InitialAppLoadingIndicator,
} from '@/components/AuthenticationManager'
import { EnvironmentBanner } from '@/components/EnvironmentBanner/EnvironmentBanner'
import { LoginAsTopBar } from '@/components/LoginAs'
import { Login } from '@/components/Pages/Login/Login'
import I18nContextProvider from '@/intl/I18nContext'
import { RoutesHandler } from '@/router'
import { TestQueryClientWatcher } from '@/test/TestQueryClientWatcher'
import { loadAnalytics } from '@/thirdParties/analytics'
import { initDatadog } from '@/thirdParties/datadog'
import { sendErrorToSentry } from '@/thirdParties/sentry'
import '@/thirdParties/sentry/config'
import { loadStonly } from '@/thirdParties/stonly/stonly'
import { loadDelighted } from '@/thirdParties/survey/delightedSurvey'
import { CountdownContextProvider } from '@/utils/useCountdown'
import { Toaster } from '@alma/react-components'
import React, { FunctionComponent, PropsWithChildren, useEffect, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import { HelpCenterButton } from '@/components/HelpCenter/HelpCenterButton'
import { PaymentCreationContextProvider } from '@/components/Pages/Home/CreatePaymentPage/PaymentCreationContext/PaymentCreationContext'

type Props = {
  initialEntries?: string[]
}
export const App: FunctionComponent<PropsWithChildren<Props>> = ({ initialEntries, children }) => {
  const queryClient = useMemo(
    () =>
      new QueryClient(
        process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'storybook'
          ? { defaultOptions: { queries: { retry: false } } }
          : undefined
      ),
    []
  )
  // usage of memoryRouter documentation : https://testing-library.com/docs/example-react-router/#reducing-boilerplate
  const Router =
    process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'storybook'
      ? MemoryRouter
      : BrowserRouter

  // Load external services on launch
  useEffect(() => {
    Promise.all([loadDelighted(), loadStonly(), loadAnalytics()]).catch((e) =>
      sendErrorToSentry(e, {
        level: 'debug',
        fingerprint: ['external-services'],
      })
    )
    initDatadog()
  }, [])

  useEffect(
    () => () => {
      void queryClient.cancelQueries()
    },
    [queryClient]
  )

  return (
    <GlobalErrorBoundary>
      <I18nContextProvider loading={<InitialAppLoadingIndicator />}>
        <TranslatedErrorBoundary>
          <QueryClientProvider client={queryClient}>
            {(process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'storybook') && (
              <TestQueryClientWatcher />
            )}
            <Router initialEntries={initialEntries}>
              <CountdownContextProvider>
                <EmbeddedProvider
                  loading={
                    process.env.NODE_ENV === 'storybook' ? (
                      <InitialAppLoadingIndicator
                        className="static-loader" // className is defined in .storybook/preview-head.html
                      />
                    ) : (
                      <InitialAppLoadingIndicator />
                    )
                  }
                >
                  <Toaster />
                  <AuthenticationManager loading={<InitialAppLoadingIndicator />} login={<Login />}>
                    <HelpCenterButton />
                    <AccessChecker />
                    <LoginAsTopBar />
                    <EnvironmentBanner />
                    <AdyenGatewayProvider>
                      <PaymentCreationContextProvider>
                        <RoutesHandler />
                      </PaymentCreationContextProvider>
                    </AdyenGatewayProvider>
                  </AuthenticationManager>
                  {children}
                </EmbeddedProvider>
              </CountdownContextProvider>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </TranslatedErrorBoundary>
      </I18nContextProvider>
    </GlobalErrorBoundary>
  )
}
