import s from '@/components/PaymentsModal/Templates/Templates.module.css'
import { Collapsible } from '@alma/react-components'
import React, { FunctionComponent, PropsWithChildren, useState } from 'react'
import { FormattedMessage } from 'react-intl'

export const ItDoesNotWorkCollapsible: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true)
  return (
    <Collapsible
      title={
        <div className={s.itDoesNotWorkTitle} data-testid="it-does-not-work-collapsible">
          <FormattedMessage
            id="payment.details.it-does-not-work.collapsible"
            defaultMessage="It does not work ?"
            description="On the payment details modal, there is a collapsible to explain what to do on the current step if the customer encounters an issue."
          />
        </div>
      }
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
    >
      {children}
    </Collapsible>
  )
}
