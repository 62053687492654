import { useMe } from '@/components/AuthenticationManager'
import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import { ButtonsAction, GreyZone, Header } from '@/components/PaymentsModal/Templates'
import { VerticalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import { hasFeatureTag, ActiveTags } from '@/types'
import { IconName } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

export const Rejected: VoidFunctionComponent<
  StepProps & {
    isUserMDM: boolean
  }
> = ({ amountAndPlan, customerName, createdDate, ActionButtons, isUserMDM }) => {
  const me = useMe()
  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />
      <GreyZone>
        <VerticalTitle icon={{ icon: IconName.close, color: 'var(--alma-red)' }}>
          <FormattedMessage
            id="payment.detail.requirementStep.rejected"
            defaultMessage="Funding request refused"
            description="Title the payment requirement step when we could not accept the payment"
          />
        </VerticalTitle>
        <div>
          <FormattedMessage
            id="payment.step.detail.content.rejected"
            defaultMessage=" After analyzing your customer's information, we cannot accept to finance their purchase. Your customer can try again with another credit card."
            description="Content the payment requirement step when we could not accept the payment"
          />
        </div>
      </GreyZone>
      <ButtonsAction>
        {/* TODO: Maybe we can find an other way to handle mdm check (maybe more globally) */}
        {isUserMDM || hasFeatureTag(ActiveTags.cantExitFinalize, me) ? (
          <ActionButtons.Close color="primary" block />
        ) : (
          <ActionButtons.Restart color="primary" block />
        )}
      </ButtonsAction>
    </>
  )
}
