import { getMe, postLogin } from '@/apis/api'
import { useSelectMerchantMutation } from '@/apis/hooks'
import { isRedirectMe } from '@/apis/utils'
import { ME_QUERY_KEY } from '@/components/AuthenticationManager/AuthenticationManagerContext'
import { PaymentStatus } from '@/types'
import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

const EmbeddedMessage = z.union([
  z.object({
    type: z.literal('loginWith'),
    email: z.string(),
    password: z.string(),
    merchantId: z.string().optional(),
    nonce: z.string(),
  }),
  z.object({
    type: z.literal('promptLogin'),
    nonce: z.string(),
  }),
])
// eslint-disable-next-line no-redeclare,@typescript-eslint/no-redeclare -- Keep type the same name as type
type EmbeddedMessage = z.infer<typeof EmbeddedMessage>

type EmbeddedInfo = {
  embedded: boolean
  nonce: string
  publishPaymentStatus(paymentId: string, paymentStatus: PaymentStatus): void
}

const WEBAPP: EmbeddedInfo = {
  embedded: false,
  nonce: `--${Math.random()}--`,
  publishPaymentStatus() {},
}
const EmbeddedContext = createContext<EmbeddedInfo>(WEBAPP)

type Props = {
  loading: ReactNode
}

export const EmbeddedProvider: FunctionComponent<PropsWithChildren<Props>> = ({
  loading,
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [context] = useState<EmbeddedInfo>(() => {
    const nonce = searchParams.get('embedded')
    return nonce
      ? {
          embedded: true,
          nonce,
          publishPaymentStatus(paymentId, paymentStatus) {
            window.parent.postMessage({ type: 'paymentValidated', paymentId, paymentStatus, nonce })
          },
        }
      : WEBAPP
  })
  const [isAwaitingFirstParentMessage, setIsAwaitingFirstParentMessage] = useState(context.embedded)
  const queryClient = useQueryClient()
  const selectMerchantMutation = useSelectMerchantMutation()
  const { mutate: doLogin } = useMutation(
    ['login from parent'],
    async ({
      email,
      password,
      merchantId,
    }: Pick<
      Extract<EmbeddedMessage, { type: 'loginWith' }>,
      'email' | 'password' | 'merchantId'
    >) => {
      try {
        const me = await postLogin({ email, password })

        if (isRedirectMe(me)) {
          return
        }

        if (merchantId && me.merchant.id !== merchantId) {
          await selectMerchantMutation.mutateAsync({ merchantId })
          queryClient.setQueryData(ME_QUERY_KEY, await getMe())
        } else {
          queryClient.setQueryData(ME_QUERY_KEY, me)
        }
      } catch (e) {
        // Ignore login error, just let the app go to the login page
      }
    }
  )

  useEffect(() => {
    if (searchParams.has('embedded')) {
      const urlSearchParams = new URLSearchParams(searchParams)
      urlSearchParams.delete('embedded')
      setSearchParams(urlSearchParams, { replace: true })
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    if (!context.embedded) {
      return undefined
    }
    const listener = (e: MessageEvent<unknown>) => {
      const { data, source } = e

      if (source !== window.parent) {
        return
      }

      const decoded = EmbeddedMessage.safeParse(data)

      if (!decoded.success) {
        // eslint-disable-next-line no-console
        console.warn('received invalid message from parent', decoded.error)
        return
      }

      if (decoded.data.nonce !== context.nonce) {
        // eslint-disable-next-line no-console
        console.warn('invalid nonce in message')
        return
      }

      if (decoded.data.type === 'loginWith') {
        doLogin(decoded.data)
      }

      setIsAwaitingFirstParentMessage(false)
    }
    window.addEventListener('message', listener)

    window.parent.postMessage({ type: 'ready', nonce: context.nonce })

    return () => window.removeEventListener('message', listener)
  }, [context.embedded, context.nonce, doLogin])

  if (isAwaitingFirstParentMessage) {
    return <>{loading}</>
  }

  return <EmbeddedContext.Provider value={context}>{children}</EmbeddedContext.Provider>
}

export const useEmbedded = () => useContext(EmbeddedContext)
