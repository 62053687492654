import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import React, { FunctionComponent, PropsWithChildren } from 'react'

export const List: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <ul className={s.stepList}>{children}</ul>
)

export const ListItem: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <li>
    <div className={s.listDotSpan} />
    {children}
  </li>
)
