import { useTerminalPayment } from '@/apis/adyenGateway'
import { useCancelPaymentMutation } from '@/apis/hooks'
import { useMe } from '@/components/AuthenticationManager'
import { ActionsModal } from '@/components/Modals/ActionsModal/ActionsModal'
import { HomeState } from '@/components/Pages/Home/CreatePaymentPage/Home'
import { ClickTracker } from '@/thirdParties/analytics'
import { ActiveTags, hasFeatureTag, isPaymentOnTerminal, Payment } from '@/types'
import { useGoBack } from '@/utils/useGoBack'
import { Button, ButtonProps, toast } from '@alma/react-components'
import React, { useCallback, useState, FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { UseMutationResult } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { config } from '@/config'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'

type ActionButtonProps = Omit<ButtonProps, 'onClick' | 'children'> & AdditionalButtonProps

type ButtonsProps = {
  block?: boolean
  color: 'link' | 'default'
}

type AdditionalButtonProps = {
  paymentId?: string
}

type ModalsProps = {
  onCancelled(): void
}

export type WithActionButtonsProps = {
  ActionButtons: {
    Cancel: React.FunctionComponent<ActionButtonProps>
    Restart: React.FunctionComponent<ActionButtonProps>
    Close: React.FunctionComponent<ActionButtonProps>
    NewPayment: React.FunctionComponent<ActionButtonProps>
    GoToDashboard: React.FunctionComponent<ActionButtonProps>
  }
}
type UsePaymentDetailButtonsResponse = {
  cancelOrRestartIsOpened: boolean
  CancelOrRestartButtons: React.FunctionComponent<ButtonsProps>
  CloseAndRestartButtons: React.FunctionComponent
  CancelOrRestartDialogs: React.FunctionComponent<ModalsProps>
  cancelPaymentMutation: UseMutationResult<void, unknown, void, unknown>
} & WithActionButtonsProps

export function useActionsButtons(
  track: ClickTracker,
  payment: Payment | undefined
): UsePaymentDetailButtonsResponse {
  const me = useMe()
  const intl = useIntl()
  const navigate = useNavigate()
  const goBack = useGoBack('/payments')

  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [restartModalOpen, setRestartModalOpen] = useState(false)

  const { terminatePaymentOnTerminal } = useTerminalPayment(
    isPaymentOnTerminal(payment) ? payment.id : undefined
  )
  const cancelPaymentMutation = useCancelPaymentMutation(me.merchant.id, payment?.id, {
    onMutate: () => terminatePaymentOnTerminal(),
    onSuccess: () => {
      track('cancel_payment_success')
      toast.success(
        intl.formatMessage({
          id: 'toast.payment.cancel.success',
          defaultMessage:
            'The payment has been successfully cancelled, the status will be updated in a few minutes.',
          description:
            'Content of the toast message that is displayed after a payment is cancelled.',
        })
      )
    },
    onError: () => {
      track('cancel_payment_fail')
    },
    errorParser: {
      userMessage: intl.formatMessage({
        id: 'toast.error.payment.cancel',
        defaultMessage: 'Unable to cancel payment',
        description: `This is a message displayed when the seller tried to cancel a payment link and it didn't work for technical reasons. It is displayed for 5 seconds as a confirmation.`,
      }),
    },
  })

  const onConfirmCancel = useCallback(
    async (onCancelled: () => void) => {
      if (!payment) return
      try {
        if (payment.cancelable) await cancelPaymentMutation.mutateAsync(undefined)
        onCancelled()
      } catch (error) {
        // Error handling is done by hooks on the mutation
        // however awaiting here causes an error to be thrown in this context
      }
    },
    [cancelPaymentMutation, payment]
  )

  const onConfirmRestart = useCallback(() => {
    if (!payment) return
    if (payment.cancelable) cancelPaymentMutation.mutate()

    track('restart_payment')
    navigate('/', { state: { fromPaymentId: payment.id } as HomeState, replace: true })
  }, [cancelPaymentMutation, payment, navigate, track])

  const hasPayment = Boolean(payment)

  const GoToDashboard = useCallback<FunctionComponent<ActionButtonProps>>(
    (props) => {
      const { paymentId } = props
      return (
        <Button block color="secondary">
          <a
            href={`${config.DASHBOARD_URL}/payments/${paymentId}`}
            target="_blank"
            rel="noreferrer"
            className={s.link}
            onClick={() => {
              track('payment_see_in_dashboard')
            }}
          >
            <FormattedMessage
              id="paymentDetails.show.in.dashboard"
              defaultMessage="Show in dashboard"
              description="In the detail payment popup, when the payment is validated and the user is at least an accountant, there is a CTA to navigate to the payment's page on the merchant dashboard"
            />
          </a>
        </Button>
      )
    },
    [track]
  )

  const Cancel = useCallback<FunctionComponent<ActionButtonProps>>(
    (props) =>
      payment?.cancelable ? (
        <Button {...props} onClick={() => setCancelModalOpen(true)}>
          <FormattedMessage
            id="paymentDetails.cancel"
            defaultMessage="Cancel transaction"
            description="There are 2 CTAs in the popup where the seller can track the progress of the end customer's payment. One CTA allows the seller to cancel the payment (the other is to start over). This can be useful if the customer infos are not filled correctly for instance"
          />
        </Button>
      ) : null,
    [payment?.cancelable]
  )

  const Restart = useCallback<FunctionComponent<ActionButtonProps>>(
    (props) =>
      !hasFeatureTag(ActiveTags.mdm, me) ? (
        <Button
          {...props}
          onClick={() => setRestartModalOpen(true)}
          data-testid="restart-payment-button"
        >
          <FormattedMessage
            id="paymentDetails.restart"
            defaultMessage="Start payment over"
            description="There are 2 CTAs in the popup where the seller can track the progress of the end customer's payment. One CTA allows the seller to start the payment again (the other is to cancel the payment). This can be useful if the payment link was expired"
          />
        </Button>
      ) : null,
    [me]
  )
  const Close = useCallback<FunctionComponent<ActionButtonProps>>(
    (props) => (
      <Button {...props} onClick={goBack}>
        <FormattedMessage
          id="paymentDetails.close"
          defaultMessage="Close"
          description="In the detail payment popup, in some cases there is a CTA to close the popup."
        />
      </Button>
    ),
    [goBack]
  )
  const NewPayment = useCallback<FunctionComponent<ActionButtonProps>>(
    (props) =>
      !hasFeatureTag(ActiveTags.mdm, me) ? (
        <Button {...props} onClick={() => setRestartModalOpen(true)}>
          <FormattedMessage
            id="paymentDetails.success-restart"
            defaultMessage="Create a new payment"
            description="There are 2 CTAs in the popup where the seller can track the progress of the end customer's payment. One CTA allows the seller to start the payment again (the other is to close the pop up). This can be useful if the payment link was expired"
          />
        </Button>
      ) : null,
    [me]
  )

  return {
    cancelOrRestartIsOpened: cancelModalOpen || restartModalOpen,
    CancelOrRestartButtons: useCallback<FunctionComponent<ButtonsProps>>(
      ({ color, block }) => (
        <>
          <Cancel block={block} color={color === 'default' ? 'primary-destructive' : 'link-red'} />
          {!hasFeatureTag(ActiveTags.cantExitFinalize, me) && (
            <Restart block={block} color={color === 'default' ? 'secondary' : 'link'} />
          )}
        </>
      ),
      [Cancel, Restart, me]
    ),
    CloseAndRestartButtons: useCallback<FunctionComponent>(
      () => (
        <>
          <NewPayment block color="secondary" />
          <Close block color="primary" />
        </>
      ),
      [Close, NewPayment]
    ),
    CancelOrRestartDialogs: useCallback<FunctionComponent<ModalsProps>>(
      ({ onCancelled }) =>
        hasPayment ? (
          <>
            <ActionsModal
              isOpen={cancelModalOpen}
              onClose={() => setCancelModalOpen(false)}
              header={
                <FormattedMessage
                  id="modal.confirm.cancel.header"
                  defaultMessage="Cancel transaction"
                  description="Title of the confirmation modal when a user asks to cancel a payment"
                />
              }
              actions={
                <>
                  <Button
                    color="secondary"
                    onClick={() => setCancelModalOpen(false)}
                    disabled={cancelPaymentMutation.isLoading}
                  >
                    <FormattedMessage
                      id="modal.cancel"
                      defaultMessage="back"
                      description="This is one of the 2 CTA in the confirmation message displayed when the seller tried to cancel a payment, this one cancels the cancellation and the seller goes back to the past payment list"
                    />
                  </Button>
                  <Button
                    color="primary-destructive"
                    onClick={() => onConfirmCancel(onCancelled)}
                    disabled={cancelPaymentMutation.isLoading}
                  >
                    <FormattedMessage
                      id="modal.confirm.cancel.confirm"
                      defaultMessage="Cancel transaction"
                      description="Action button for the payment cancellation confirmation modal. Clicking this button confirms that the user wishes to cancel the payment."
                    />
                  </Button>
                </>
              }
            >
              <p>
                <FormattedMessage
                  id="modal.confirm.cancel.message"
                  defaultMessage="This action will cancel the payment in progress, it will be necessary to recreate a new payment so that your customer can pay."
                  description="This is confirmation message displayed when the seller choses to cancel a payment from the past payment list"
                />
              </p>
              <p>
                <FormattedMessage
                  id="modal.confirm.certain"
                  defaultMessage="Are you certain?"
                  description="Content of the payment cancellation confirmation modal"
                />
              </p>
            </ActionsModal>
            <ActionsModal
              isOpen={restartModalOpen}
              onClose={() => setRestartModalOpen(false)}
              header={
                <FormattedMessage
                  id="modal.confirm.restart.header"
                  defaultMessage="Start payment over"
                  description="Title of the payment restart confirmation modal."
                />
              }
              actions={
                <>
                  <Button
                    color="secondary"
                    onClick={() => setRestartModalOpen(false)}
                    disabled={cancelPaymentMutation.isLoading}
                  >
                    <FormattedMessage
                      id="modal.cancel"
                      defaultMessage="back"
                      description="This is one of the 2 CTA in the confirmation message displayed when the seller tried to cancel a payment, this one cancels the cancellation and the seller goes back to the past payment list"
                    />
                  </Button>
                  {!hasFeatureTag(ActiveTags.cantExitFinalize, me) && (
                    <Button
                      data-testid="restart-confirmation-button"
                      color="primary"
                      onClick={onConfirmRestart}
                      disabled={cancelPaymentMutation.isLoading}
                    >
                      <FormattedMessage
                        id="modal.confirm.restart.confirm"
                        defaultMessage="Start payment over"
                        description="Action button of the payment restart confirmation modal. Clicking this button will cancel the current payment and create a new one with the same characteristics."
                      />
                    </Button>
                  )}
                </>
              }
            >
              <p>
                <FormattedMessage
                  id="modal.confirm.restart.message"
                  defaultMessage="Starting this payment over wil deactivate the link sent to the client, you will keep informations filled during form step to create a new link."
                  description="Additional information for the payment restart confirmation modal."
                />
              </p>
            </ActionsModal>
          </>
        ) : null,
      [
        hasPayment,
        cancelModalOpen,
        cancelPaymentMutation.isLoading,
        restartModalOpen,
        me,
        onConfirmRestart,
        onConfirmCancel,
      ]
    ),
    ActionButtons: {
      Close,
      Cancel,
      Restart,
      NewPayment,
      GoToDashboard,
    },
    cancelPaymentMutation,
  }
}
