import { eligibilityCheck } from '@/apis/api'
import { CacheKeys } from '@/apis/hooks/CacheKeys'
import { useSafeQuery, UseSafeQueryOptions } from '@/apis/hooks/utils'
import { useMe } from '@/components/AuthenticationManager'
import { Eligibility, EligibilityRequest, FeePlan } from '@/types'

const getEligibilityPayload = (feePlan: FeePlan, amountInCents: number): EligibilityRequest => ({
  purchase_amount: amountInCents,
  queries: [
    {
      deferred_days: feePlan.deferred_days,
      deferred_months: feePlan.deferred_months,
      deferred_trigger: Boolean(feePlan.deferred_trigger_limit_days),
      installments_count: feePlan.installments_count,
    },
  ],

  origin: 'pos',
})

export const useEligibilityQuery = (
  feePlan: FeePlan | undefined,
  amountInCents: number,
  options?: UseSafeQueryOptions<Eligibility[], unknown, Eligibility>
) => {
  const me = useMe()
  const eligibilityData =
    feePlan && amountInCents ? getEligibilityPayload(feePlan, amountInCents) : undefined
  return useSafeQuery<Eligibility[], unknown, Eligibility>(
    [CacheKeys.Eligibility, me.merchant.id, eligibilityData],
    async ({ signal }) =>
      eligibilityData ? eligibilityCheck(eligibilityData, me.merchant.id, signal) : [],
    {
      ...options,
      enabled: !me.merchant.customer_choose_installments && Boolean(eligibilityData),
      select: (eligibility) => eligibility[0],
    }
  )
}
