import { toast } from '@alma/react-components'
import { IntlShape } from 'react-intl'
import { useMemo, useRef } from 'react'

export const copyToClipboard = async (text: string) => {
  if (window.navigator.clipboard) {
    await navigator.clipboard.writeText(text)
  } else if (document.execCommand) {
    const clipBoardElem = document.createElement('input')
    // we want to minimize the chance that this input appear on the screen
    // TS don't allow us to make something like clipBoardElem.style = {position: 'absolute'}
    clipBoardElem.style.position = 'absolute'
    clipBoardElem.style.right = '-100px'
    clipBoardElem.style.top = '-100px'

    document.body.appendChild(clipBoardElem)
    clipBoardElem.value = text
    try {
      clipBoardElem.select()
      document.execCommand('copy')
    } finally {
      document.body.removeChild(clipBoardElem)
    }
  } else {
    throw new Error(
      'b9032368: Called copy on a browser without clipboard support, check "canCopy" if this' +
        ' error throw'
    )
  }
}

export const canCopy = () => window.navigator.clipboard || document.execCommand

export const COPY_IS_DISABLED = Symbol('copy is disabled')

export const useCopy = (
  clipboardText: string,
  validateMessage: string,
  track: () => void,
  intl: IntlShape
) => {
  const latestTrack = useRef(track)
  latestTrack.current = track

  return useMemo(
    () =>
      canCopy()
        ? async () => {
            latestTrack.current()

            try {
              await copyToClipboard(clipboardText)
              toast.info(validateMessage)
            } catch (e) {
              toast.error(
                intl.formatMessage({
                  id: 'toast.copied.error',
                  defaultMessage: 'Error while copying in clipboard',
                  description:
                    'This is a message displayed when the text could not be copied. It is displayed for 5 seconds as a' +
                    ' confirmation.',
                })
              )
            }
          }
        : COPY_IS_DISABLED,
    [clipboardText, validateMessage, intl]
  )
}
