import { useMe } from '@/components/AuthenticationManager'
import { useDisableField } from '@/components/Forms/disabledFields'
import s from '@/components/Forms/PaymentCreate/PhoneNumberForm/PhoneNumberForm.module.css'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { useValidatePhoneNumber } from '@/components/Forms/validation'
import { isCountry, PhoneNumberField } from '@alma/react-components'
import React, { VoidFunctionComponent } from 'react'
import { Controller, DeepPartial, useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

export const DEFAULT_PHONE_NUMBER_VALUES: DeepPartial<PaymentCreateFormValues> = {
  phone: { number: '' },
}

export const PhoneNumberForm: VoidFunctionComponent<{
  name?: 'phone.number' | 'update.phone.number'
}> = ({ name = 'phone.number' }) => {
  const me = useMe()
  const { control } = useFormContext<PaymentCreateFormValues>()
  const { disableField } = useDisableField<PaymentCreateFormValues>()
  const [validateNumber, onInvalidNumber] = useValidatePhoneNumber()

  return (
    <div className={s.fieldGroup}>
      <Controller
        control={control}
        name={name}
        rules={{
          validate: { validateNumber },
        }}
        render={({ field, fieldState }) => (
          <PhoneNumberField
            {...field}
            onChange={(value) => {
              /* When onChange is triggered with `undefined` there is a weird behavior that reset the default value instead of keeping the field empty */
              field.onChange(value ?? '')
            }}
            id={name}
            defaultCountry={[me.request_country, me.merchant.country].find(isCountry) ?? 'FR'}
            error={fieldState.error?.message}
            label={
              <FormattedMessage
                id="customer.info.form.phone"
                defaultMessage="Customer's mobile phone number"
                description="Field label in the customer details section of POS page. This field contains a country selector (drop down list of countries) and a free space to enter a phone number. The format of the field is checked. Only numbers are expected. If the number is not valid, or if the field is not filled-in, an error message appears in red below the field."
              />
            }
            disabled={disableField(name)}
            onInvalid={onInvalidNumber}
            countryCallingCodeEditable={false}
            addInternationalOption
          />
        )}
      />
    </div>
  )
}
