import { PaymentMethod } from '@/apis/hooks'
import { useMe } from '@/components/AuthenticationManager'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { PaymentMethodsHandler } from '@/components/Pages/Home/usePaymentMethods'
import { PaymentMethodButtons } from '@/components/PaymentMethodButtons/PaymentMethodButtons'
import s from '@/components/PaymentSubmit/PaymentSubmit.module.css'
import { SelectTerminal } from '@/components/SelectTerminal/SelectTerminal'
import { ActiveTags, hasFeatureTag } from '@/types'
import { handleSubmitSilent } from '@/utils/forms'
import { Badge, Button, CardTemplate, Header, IconName, Modal } from '@alma/react-components'
import React, {
  BaseSyntheticEvent,
  useCallback,
  useMemo,
  useState,
  VoidFunctionComponent,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router'

interface PaymentSubmitProps {
  handler: PaymentMethodsHandler
}

export const PaymentSubmit: VoidFunctionComponent<PaymentSubmitProps> = ({ handler }) => {
  const me = useMe()
  const intl = useIntl()
  const { handleSubmit, trigger, watch } = useFormContext<PaymentCreateFormValues>()
  const { payBySms, payOnScreen, payByLink, payWithTerminal, lastMethodUsed } = handler
  const mode = watch('requirements.mode')

  const [selectMethodModalOpen, setSelectMethodModalOpen] = useState(false)

  const onOpenMethodsModal = useCallback(async () => {
    const valid = await trigger()
    if (valid) {
      setSelectMethodModalOpen(true)
    }
  }, [trigger])

  const selectMethod = useCallback(
    async (method: PaymentMethod, event: BaseSyntheticEvent) => {
      setSelectMethodModalOpen(false)
      const handlers = {
        [PaymentMethod.sms]: payBySms,
        [PaymentMethod.link]: payByLink,
        [PaymentMethod.onScreen]: payOnScreen,
        [PaymentMethod.terminal]: payWithTerminal,
      }
      await handleSubmitSilent(handlers[method], handleSubmit)(event)
    },
    [payBySms, payByLink, payOnScreen, payWithTerminal, handleSubmit]
  )

  const methodOptions = useMemo(() => {
    const options: PaymentMethod[] = []
    if (me.merchant.has_pos_pay_by_sms) options.push(PaymentMethod.sms)
    if (me.merchant.has_pos_pay_by_link) options.push(PaymentMethod.link)
    if (me.merchant.has_pos_pay_on_device) options.push(PaymentMethod.onScreen)
    return options
  }, [me])

  // 700px is a value that is calculated with actual height of left column to determine if the screen is small enough to display the payment method buttons
  // be careful the jest's window is lower than 700px
  const hasEnoughHeight = useMediaQuery({ query: '(min-height: 700px)' })
  const isEPT = me.use_terminal && me.terminals.length > 0 && hasFeatureTag(ActiveTags.ept, me)
  const location = useLocation()
  const mdmShouldNotDisplayEPTButton =
    !location.pathname.includes('/finalize') && hasFeatureTag(ActiveTags.mdm, me)

  const hasFastModeAndNotEnoughHeight =
    hasFeatureTag(ActiveTags.fastMode, me) && methodOptions.length > 1 && !hasEnoughHeight

  // Only one button is displayed : The button opens a modal to show the other payment's method
  const withModalLayout = (useEPT = false) => (
    <Button block onClick={onOpenMethodsModal} color={useEPT ? 'secondary' : 'primary'}>
      {useEPT ? (
        <FormattedMessage
          id="home.submit.ept.other"
          defaultMessage="Other payment methods"
          description="When paying from an EPT, button to access to other payment method through a modal"
        />
      ) : (
        <FormattedMessage
          id="home.submit.selectMethod"
          defaultMessage="Select payment method"
          description="When multiple payment are possible, this is the button that opens the modal in which the user will select the payment method to use"
        />
      )}
    </Button>
  )

  // The payment's method buttons are displayed directly with no modal in between
  const withAllOptionsLayout = (useEPT = false) => (
    <PaymentMethodButtons
      isEPT={useEPT}
      options={methodOptions}
      onClick={selectMethod}
      lastMethodUsed={lastMethodUsed}
    />
  )

  return (
    <>
      <CardTemplate
        padding="lg"
        header={
          <Header size="sm" className={s.paymentTitle}>
            <FormattedMessage
              id="home.pay.title"
              defaultMessage="Payment"
              description="This is the title of the part of the payment form where the different payment CTAs are (pay by SMS, pay by link and pay on screen)"
            />
            {mode === 'fast' && (
              <Badge
                color="yellow"
                label={intl.formatMessage({
                  id: 'fast.mode.activated.badge',
                  defaultMessage: 'Fast mode enabled',
                  description:
                    'When fast mode is activated, a badge is displayed to remind the merchant before they validate they payment',
                })}
                icon={IconName.lightning}
              />
            )}
          </Header>
        }
        data-stonly-anchor="paymentMethods"
      >
        {/* When fast mode is enabled, the payments methods button are displayed in a modale if the window's height is too small to display them all */}
        {hasFastModeAndNotEnoughHeight && withModalLayout()}
        {/* When EPT is enabled, the payments methods button are displayed in a modale if there is more than 1 other method */}
        {isEPT && (
          <div className={s.eptLayout}>
            <SelectTerminal
              mdmShouldNotDisplayEPT={mdmShouldNotDisplayEPTButton}
              onSubmit={(e) => selectMethod(PaymentMethod.terminal, e)}
            />
            {methodOptions.length > 1 && !mdmShouldNotDisplayEPTButton
              ? withModalLayout(isEPT)
              : withAllOptionsLayout(isEPT)}
          </div>
        )}
        {/* By default, all the buttons for payment methods are displayed */}
        {!isEPT && !hasFastModeAndNotEnoughHeight && withAllOptionsLayout()}
      </CardTemplate>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={selectMethodModalOpen}
        onClose={() => setSelectMethodModalOpen(false)}
        size="md"
      >
        <div className={s.methodModal}>
          <Header level={6} as="h2">
            <FormattedMessage
              id="modal.selectMethod.header"
              defaultMessage="Payment method"
              description="This is the title of the modal window in which the user will be able to select which payment method he wants to use"
            />
          </Header>
          <PaymentMethodButtons
            options={methodOptions}
            onClick={selectMethod}
            lastMethodUsed={lastMethodUsed}
          />
        </div>
      </Modal>
    </>
  )
}
