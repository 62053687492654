import { useMe } from '@/components/AuthenticationManager'
import s from '@/components/CustomerInfo/CustomerInfo.module.css'
import { CustomDataForm, CustomerInfoForm, OrderForm } from '@/components/Forms/PaymentCreate'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'
import { ActiveTags, hasFeatureTag } from '@/types'
import { CardTemplate, CardTemplateCollapsible, Header } from '@alma/react-components'
import React, { ReactNode, VoidFunctionComponent } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

export const CustomerInfo: VoidFunctionComponent<TrackerProps> = ({ track }) => {
  const { watch } = useFormContext<PaymentCreateFormValues>()
  const me = useMe()
  const {
    field: { onChange },
  } = useController<PaymentCreateFormValues, 'customer.filledBy'>({
    name: 'customer.filledBy',
  })

  const filledBy = watch('customer.filledBy')

  const title = (
    <FormattedMessage
      id="home.customer.info.form.title"
      defaultMessage="Your customer's information"
      description="This is the title of the customer information card"
    />
  )
  return (
    <>
      {me.merchant.can_customer_info_be_optional ? (
        <CardTemplateCollapsible
          padding="lg"
          data-stonly-anchor="customerInfoOptional"
          header={
            <Header size="sm" as="h2">
              {title}
            </Header>
          }
          chevronColor="var(--alma-orange)"
          className={s.collapsibleInfo}
          description={
            filledBy === 'customer' ? (
              <div className={s.infoText} data-testid="customer-information-block-collapsed">
                {me.use_terminal && me.terminals.length > 0 && hasFeatureTag(ActiveTags.ept, me) ? (
                  <FormattedMessage
                    id="home.customer.info.form.description.expand.ept"
                    defaultMessage="Your customer will fill in his information on his payment page. <info>For a payment by terminal, we invite you to fill them in for him.</info>"
                    description="This is the description of the customer information card when the card is expanded and have ept configured"
                    values={{
                      info: (chunk: ReactNode) => <div>{chunk}</div>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="home.customer.info.form.description.expand"
                    defaultMessage="Your customer will fill in their information themselves on their checkout page. <info>Scroll down this menu to fill them in for them.</info>"
                    description="This is the description of the customer information card when the card is expanded"
                    values={{
                      info: (chunk: ReactNode) => <div>{chunk}</div>,
                    }}
                  />
                )}
              </div>
            ) : (
              <div className={s.infoText} data-testid="customer-information-block-not-collapsed">
                <FormattedMessage
                  id="home.customer.info.form.description.collapse"
                  defaultMessage="Please fill in your customer's information. <info>Close this menu to let your customer fill them in on their payment page.</info>"
                  description="This is the description of the customer information card when the card is collapsed"
                  values={{
                    info: (chunk: ReactNode) => <div>{chunk}</div>,
                  }}
                />
              </div>
            )
          }
          collapsed={filledBy === 'customer'}
          onCollapse={(value) => {
            track(value ? 'customer_info_optional_open' : 'customer_info_optional_close')
            return onChange(value ? 'merchant' : 'customer')
          }}
        >
          <div className={s.formExpanded}>
            <CustomerInfoForm track={track} />
          </div>
        </CardTemplateCollapsible>
      ) : (
        <CardTemplate
          header={<Header size="sm">{title}</Header>}
          padding="lg"
          data-stonly-anchor="customerInfo"
        >
          <CustomerInfoForm track={track} />
        </CardTemplate>
      )}

      <CardTemplate
        padding="lg"
        header={
          <Header size="sm">
            <FormattedMessage
              id="home.seller.info.card.title"
              defaultMessage="Seller information"
              description="This is the title of the seller information card"
            />
          </Header>
        }
      >
        <div className={s.sellerForms}>
          <OrderForm />
          <CustomDataForm />
        </div>
      </CardTemplate>
    </>
  )
}
