import { config } from '@/config'
import { sendErrorToSentry } from '@/thirdParties/sentry'
import { toast } from '@alma/react-components'
import { SeverityLevel } from '@sentry/browser'
import { Extras } from '@sentry/types'
import axios, { AxiosError } from 'axios'

export interface PosErrorOptions {
  /** Error message that is logged */
  message: string
  /** Contextual data to log  */
  data?: Extras
  /** Additional tags for the error displayed in Sentry */
  tags?: Record<string, string | undefined>
  /** Message that should be displayed to the user (setting to null disables the default error message) */
  userMessage?: string
  severity?: SeverityLevel
  /**
   * Sentry [fingerprint](https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting/)
   */
  fingerprint?: string[]
}

export class PosError extends Error {
  public readonly severity: SeverityLevel

  public readonly data?: Extras

  public readonly tags?: Record<string, string | undefined>

  public readonly userMessage?: string

  public readonly fingerprint?: string[]

  public constructor({ message, severity, data, tags, userMessage, fingerprint }: PosErrorOptions) {
    super(message)
    this.severity = severity ?? 'error'
    this.data = data
    this.tags = tags
    this.userMessage = userMessage
    this.fingerprint = fingerprint
  }
}

export function handleError(error: unknown) {
  if (!(error instanceof PosError) || ['error', 'fatal'].includes(error.severity))
    sendErrorToSentry(error)

  /* istanbul ignore next */
  if (error instanceof PosError && error.userMessage) {
    toast.error(error.userMessage, { duration: Infinity })
  }
  /* istanbul ignore next */
  // eslint-disable-next-line no-console
  if (['local_dev', 'dev', 'staging'].includes(config.ENV)) console.error(error)
}

export interface ParseAxiosErrorOptions extends Record<string, string | undefined> {
  userMessage?: string
}

/**
 * Replace the Alma IDs in the URL paths with generic placeholders
 * Format URL to use only host and pathname (remove all params)
 */
export function cleanUrl(url: string, baseUrl?: string) {
  const parsedUrl = new URL(url, baseUrl)

  const pathnameWithNoIds = parsedUrl.pathname
    .replace(/payment_[0-9a-z-A-Z]{34}/g, ':paymentId')
    .replace(/user_[0-9a-z-A-Z]{34}/g, ':userId')
    .replace(/merchant_[0-9a-z-A-Z]{34}/g, ':merchantId')

  return parsedUrl.host + pathnameWithNoIds
}

/**
 * This is a standard parser for Axios errors
 * It's aim is to understand, categorize, and classify network related errors more easily
 */
export function parseAxiosError(error: unknown, options?: ParseAxiosErrorOptions) {
  const { userMessage } = options ?? {}
  if (axios.isAxiosError(error)) {
    const errorData = (error as AxiosError<{ error_code?: string; errors?: unknown }>).response
      ?.data
    const data = {
      code: error.code,
      name: error.name,
      message: error.message,
      config: { url: error.config.url, baseUrl: error.config.baseURL, method: error.config.method },
      status: error.response?.status,
      stack: error.stack,
      error_code: errorData?.error_code,
      errors: errorData?.error_code,
    }
    const tags = { method: error.config.method, path: error.config.url }
    const fingerprint = ['pos-network-error']

    if (error.response) {
      // We only care about the endpoint or status code when we actually get a response
      if (error.config.method) fingerprint.push(error.config.method)
      if (error.config.url) fingerprint.push(cleanUrl(error.config.url, error.config.baseURL))
      if (error.response.status) fingerprint.push(`${error.response.status}`)
      const message = `Server responded with status ${error.response.status}`
      if (error.response.status >= 500 || error.response.status === 400) {
        return new PosError({ message, data, userMessage, severity: 'error', tags, fingerprint })
      }
      return new PosError({ message, data, userMessage, severity: 'warning', tags, fingerprint })
    }
    if (error.request) {
      return new PosError({
        message: 'Request did not receive response',
        data,
        userMessage,
        severity: 'warning',
        tags,
      })
    }
    return new PosError({
      message: 'Failed to perform request',
      data,
      userMessage,
      severity: 'warning',
      tags,
    })
  }
  return new PosError({
    message: 'Something unexpected happened when performing a network request',
    data: { raw: JSON.stringify(error) },
    userMessage,
    severity: 'warning',
  })
}
