import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import { ButtonsAction, GreyZone, Header } from '@/components/PaymentsModal/Templates'
import { VerticalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import { IconName } from '@alma/react-components'
import React, { VoidFunctionComponent, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import s from '@/components/PaymentsModal/OnlineSteps/Steps/Steps.module.css'
import { sendErrorToSentry } from '@/thirdParties/sentry'
import { hasFeatureTag, ActiveTags } from '@/types'
import { useMe } from '@/components/AuthenticationManager'

export const ErrorStep: VoidFunctionComponent<StepProps> = ({
  amountAndPlan,
  customerName,
  createdDate,
  ActionButtons,
  id,
}) => {
  const me = useMe()
  useEffect(() => {
    sendErrorToSentry(new Error(`An error has occurred while getting the step`), {
      level: 'error',
      extra: {
        id,
      },
      fingerprint: ['unknown-step'],
    })
  }, [id])
  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />
      <GreyZone>
        <VerticalTitle icon={{ icon: IconName.warning, color: 'var(--alma-red)' }}>
          <FormattedMessage
            id="payment.detail.errorStep.error"
            defaultMessage="Error"
            description="Title of the payment's detail modale when the step is unknown or in error"
          />
        </VerticalTitle>
        <div className={s.greyZoneDetailContainer}>
          <p>
            <FormattedMessage
              id="payment.detail.errorStep.cannot.validate"
              defaultMessage="We are sorry, an error occurred and we cannot validate your customer's financing file.{newline}We invite you to contact the support."
              description="In the payment's detail modal, this text explains that there was and error during the validation and that the merchant should contact the support."
              values={{
                newline: <br />,
              }}
            />
          </p>
        </div>
      </GreyZone>
      <ButtonsAction>
        {!hasFeatureTag(ActiveTags.cantExitFinalize, me) && (
          <ActionButtons.Restart color="primary" block />
        )}
      </ButtonsAction>
    </>
  )
}
