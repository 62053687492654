import { PaymentMethod } from '@/apis/hooks'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'
import s from '@/components/PaymentMethodButtons/PaymentMethodButtons.module.css'
import { Button, IconName } from '@alma/react-components'
import React, { BaseSyntheticEvent, VoidFunctionComponent } from 'react'
import { useFormState } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

interface PaymentMethodButtonsProps {
  options: PaymentMethod[]
  lastMethodUsed?: PaymentMethod
  onClick: (method: PaymentMethod, e: BaseSyntheticEvent) => void
  isEPT?: boolean
}

export const PaymentMethodButtons: VoidFunctionComponent<PaymentMethodButtonsProps> = ({
  options,
  lastMethodUsed,
  onClick,
  isEPT,
}) => {
  const { isSubmitting } = useFormState<PaymentCreateFormValues>()

  // When EPT mode is activated, if there is only 1 other method, it should be secondary color
  const shouldUseSecondaryColor = isEPT && options.length === 1

  return (
    <div className={s.paymentMethodButtons}>
      {options.includes(PaymentMethod.sms) && (
        <Button
          block
          data-testid="pay-by-sms"
          // TODO : we gonna need to check the type of the onclick props, seems not normal that we've to tell him
          //  it's a MouseEvent. This come from 2.6.0 with the add of anchor polymorphisme
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClick(PaymentMethod.sms, e)
          }
          disabled={lastMethodUsed === PaymentMethod.sms || isSubmitting}
          color={shouldUseSecondaryColor ? 'secondary' : 'primary'}
          icon={IconName.phone}
        >
          <FormattedMessage
            id="home.pay-by-sms.button"
            defaultMessage="Send link by text"
            description="This is one of the available CTA on the payment form, which allows the seller to send the payment link by text message to the end customer. The end customer will receive a link allowing him to do his checkout. You can resend it another time if it didn't work. This is the most used CTA on POS."
          />
        </Button>
      )}
      {options.includes(PaymentMethod.onScreen) && (
        <Button
          block
          color={
            !options.includes(PaymentMethod.link) &&
            !options.includes(PaymentMethod.sms) &&
            !shouldUseSecondaryColor
              ? 'primary'
              : 'secondary'
          }
          data-testid="pay-on-screen"
          disabled={lastMethodUsed === PaymentMethod.onScreen || isSubmitting}
          // TODO : we gonna need to check the type of the onclick props, seems not normal that we've to tell him
          //  it's a MouseEvent. This come from 2.6.0 with the add of anchor polymorphisme
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClick(PaymentMethod.onScreen, e)
          }
          icon={IconName.shop}
        >
          <FormattedMessage
            id="home.pay-on-screen.button"
            defaultMessage="Pay on this screen"
            description="This one of the available CTA on the payment form, which allows the seller to access directly the checkout on his device. This is useful in shops where the seller has a tablet and/or a bad network so the end customer can fill his info on the seller's device"
          />
        </Button>
      )}
      {options.includes(PaymentMethod.link) && (
        <Button
          block
          color={
            options.includes(PaymentMethod.sms) || shouldUseSecondaryColor ? 'secondary' : 'primary'
          }
          data-testid="pay-by-link"
          disabled={lastMethodUsed === PaymentMethod.link || isSubmitting}
          // TODO : we gonna need to check the type of the onclick props, seems not normal that we've to tell him
          //  it's a MouseEvent. This come from 2.6.0 with the add of anchor polymorphisme
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClick(PaymentMethod.link, e)
          }
          icon={IconName.lock}
        >
          <FormattedMessage
            id="home.pay-by-link.button"
            defaultMessage="Create payment link"
            description="This is one of the available CTA on the payment form, which allows the seller to create a payment link that he can copy/paste in an email for instance or open in a browser and then access to the checkout."
          />
        </Button>
      )}
    </div>
  )
}
