import s from '@/components/PaymentsModal/Templates/Templates.module.css'
import { useLocale } from '@/intl/I18nContext'
import { Badge, Header, IconName } from '@alma/react-components'
import cx from 'classnames'
import { formatDistanceToNowStrict } from 'date-fns'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

type Props = {
  updated: Date
}
export const HorizontalTitle: FunctionComponent<PropsWithChildren<Props>> = ({
  updated,
  children,
}) => {
  const intl = useIntl()
  const { dateFnsLocale } = useLocale()
  const timeElapsed = formatDistanceToNowStrict(updated, { locale: dateFnsLocale })

  return (
    <div className={cx(s.greyZoneHorizontalTitleContainer)}>
      <Header size="md">{children}</Header>
      <Badge
        color="white"
        label={intl.formatMessage(
          {
            id: 'payment.status.time.elapsed',
            defaultMessage: 'For {time}',
            description:
              'On the payment detail status, we display the time elapsed since the last edition, step by step.',
          },
          { time: timeElapsed }
        )}
        icon={IconName.clock}
      />
    </div>
  )
}
