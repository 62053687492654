import { useMe } from '@/components/AuthenticationManager'
import s from '@/components/PageHeader/PageHeader.module.css'
import { ActiveTags, hasFeatureTag } from '@/types'
import { useGoBack } from '@/utils/useGoBack'
import { Button, Header, Icon } from '@alma/react-components'
import cx from 'classnames'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

export type Props = {
  hideCloseButton?: boolean
}

export const DefaultHeader: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  hideCloseButton = false,
}) => {
  const me = useMe()
  const intl = useIntl()
  const goBack = useGoBack('/')

  return (
    <div className={cx(s.header, { [s.loginAs]: me.real_user })} data-testid="default-header">
      <div />
      <Header size="sm" as="h1">
        {children}
      </Header>
      <div>
        {!hasFeatureTag(ActiveTags.cantExitFinalize, me) && !hideCloseButton && (
          <Button
            color="link"
            role="link"
            className={s.closeButton}
            onClick={goBack}
            data-testid="paymentsCloseButton"
            aria-label={intl.formatMessage({
              id: 'page.header.back',
              defaultMessage: 'Back to home',
              description:
                'Button at the top of the payment list page. Clicking on  it will bring you b ack to the main page of the application.',
            })}
          >
            <Icon icon="close" color="var(--alma-red)" />
          </Button>
        )}
      </div>
    </div>
  )
}
