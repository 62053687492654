import { ActiveTags, Me, hasFeatureTag } from '@/types'
import { isToday } from 'date-fns'
import React, { FC } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

type Props = {
  date: Date
  p1x: boolean
  me: Me
}

const InstallmentMessage: FC<Props> = ({ date, p1x, me }) =>
  isToday(date) ? (
    <>
      {hasFeatureTag(ActiveTags.deferredCapture, me) && p1x ? (
        <FormattedMessage
          id="installments.in.eight.days"
          defaultMessage="In 8 days"
          description="This is the first line of the installment plan displayed in the summary in the payment form, showing what the end customer will have to pay in eight days"
        />
      ) : (
        <FormattedMessage
          id="installments.today"
          defaultMessage="Today"
          description="This is the first line of the installment plan displayed in the summary in the payment form, showing what the end customer will have to pay today"
        />
      )}
    </>
  ) : (
    <FormattedDate value={date} day="numeric" month="long" year="numeric" />
  )

export default InstallmentMessage
