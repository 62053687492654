import InstallmentMessage from '@/components/Installments/InstallmentMessage/InstallmentMessage'
import s from '@/components/Installments/Installments.module.css'
import { Me, PaymentPlan } from '@/types'
import { centsToEuros } from '@/utils/prices'
import cx from 'classnames'
import { fromUnixTime } from 'date-fns'
import React, { VoidFunctionComponent } from 'react'
import { FormattedNumber } from 'react-intl'

type Props = {
  installment: PaymentPlan
  isFirstInstallment?: boolean
  offerFees: boolean
  p1x: boolean
  me: Me
}

export const Installment: VoidFunctionComponent<Props> = ({
  installment: { total_amount, purchase_amount, due_date },
  isFirstInstallment,
  offerFees,
  p1x,
  me,
}: Props) => (
  <div className={cx(s.installmentContent)}>
    <div className={s.circle}>
      <div className={cx(s.dot, { [s.firstBullet]: isFirstInstallment })} />
    </div>
    <div
      className={cx(s.installment, { [s.firstInstallment]: isFirstInstallment })}
      data-testid="installment"
    >
      <InstallmentMessage date={fromUnixTime(due_date)} p1x={p1x} me={me} />

      <div>
        <FormattedNumber
          value={centsToEuros(offerFees ? purchase_amount : total_amount)}
          style="currency"
          currency="EUR"
        />
      </div>
    </div>
  </div>
)
