import { onApiAuthenticationError } from '@/apis/api'
import { useContextMutation, useGetMeQuery } from '@/apis/hooks'
import { config } from '@/config'
import { useLocale } from '@/intl/I18nContext'
import { updateStonlyMe } from '@/thirdParties/stonly/stonly'
import { isLocale, Me } from '@/types'
import { handleError } from '@/utils'
import * as Sentry from '@sentry/browser'

import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react'

type Props = {
  login: ReactNode
  loading: ReactNode
}

export const ME_QUERY_KEY = ['me']

/* istanbul ignore next */
const ReloadAuthenticationContext = createContext<() => void>(() => undefined)
const LOGGED_OUT = Symbol('logged out')
const MeContext = createContext<Me | typeof LOGGED_OUT>(LOGGED_OUT)

export const AuthenticationManager: FunctionComponent<PropsWithChildren<Props>> = ({
  login,
  loading,
  children,
}) => {
  const me = useGetMeQuery()
  const { locale, setLocale } = useLocale()
  const meLocale = me.data?.locale
  const refetchMe = me.refetch
  const contextMutation = useContextMutation({
    onError: handleError,
    retry: false,
  })
  useEffect(() => {
    if (isLocale(meLocale)) {
      setLocale(meLocale)
    }
  }, [meLocale, setLocale])

  useEffect(() => {
    if (locale && isLocale(meLocale) && locale !== meLocale) {
      void refetchMe()
    }
  }, [locale, me.refetch, meLocale, refetchMe])

  useEffect(() => {
    if (me.data) {
      Sentry.setUser({ id: me.data.id })
      Sentry.setTags({ merchant: me.data.merchant.id, locale: me.data.locale })
      updateStonlyMe(me.data)
    } else {
      Sentry.setUser(null)
      Sentry.setTags({})
      updateStonlyMe(null)
    }
  }, [me.data])

  // Redirection to the legacy POS client when the merchant does not have the feature flag
  useEffect(() => {
    if (me.data && !me.data.merchant.use_new_pos_ui && config.LEGACY_POS_CLIENT_URL)
      window.location.replace(config.LEGACY_POS_CLIENT_URL)
  }, [me.data])

  // Refresh /me on 401 & 403
  // Using `useLayoutEffect` because we want our handler to be added to the API client _before_ the UI repaints and queries start
  useLayoutEffect(() => {
    const removeHandler = me.data ? onApiAuthenticationError(me.refetch) : undefined
    return removeHandler
  }, [me.data, me.refetch])

  // Call context API
  useEffect(() => {
    if (me.data) {
      contextMutation.mutate()
    }
    // We only want to re-run into the use effect if me changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me.data])

  if (me.isLoading) {
    return <>{loading}</>
  }

  if (!me.data || me.error) {
    return (
      <ReloadAuthenticationContext.Provider value={me.refetch}>
        {login}
      </ReloadAuthenticationContext.Provider>
    )
  }

  return (
    <ReloadAuthenticationContext.Provider value={me.refetch}>
      <MeContext.Provider value={me.data}>{children}</MeContext.Provider>
    </ReloadAuthenticationContext.Provider>
  )
}

export function useMe() {
  const me = useContext(MeContext)
  if (process.env.NODE_ENV !== 'production') {
    if (me === LOGGED_OUT) {
      throw new Error('try to use useMe in logged out context')
    }
  }
  return me as Me
}

export function useLoggedIn(): { me: Me; loggedIn: true } | { loggedIn: false } {
  const me = useContext(MeContext)
  return useMemo(
    () =>
      me === LOGGED_OUT
        ? { loggedIn: false }
        : {
            me,
            loggedIn: true,
          },
    [me]
  )
}
export function useReloadAuthentication() {
  return useContext(ReloadAuthenticationContext)
}
