import { sendErrorToSentry } from '@/thirdParties/sentry'
import { PosError } from '@/utils'
import { handleError, parseAxiosError, ParseAxiosErrorOptions } from '@/utils/error'
import { useCallback } from 'react'
import {
  MutationFunction,
  MutationKey,
  QueryFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { ZodType, ZodTypeDef } from 'zod'

interface SafeQueryOptions<TVariables = void, TData = unknown> {
  type?: ZodType<TData, ZodTypeDef, unknown>
  errorParser?:
    | ParseAxiosErrorOptions
    | ((error: unknown, context: TVariables) => ParseAxiosErrorOptions)
}

export type UseSafeQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'> &
  SafeQueryOptions<void, TData>

function extractErrorParserOption<TData, TVariables>(
  error: unknown,
  variables: TVariables,
  options?: SafeQueryOptions<TVariables, TData>
) {
  return typeof options?.errorParser === 'function'
    ? options?.errorParser(error, variables)
    : options?.errorParser
}

const handleDataValidation = <T>(
  origin: string,
  data: T,
  validationType: ZodType<unknown, ZodTypeDef, unknown> | undefined
) => {
  if (!validationType) {
    return
  }
  const validation = validationType.safeParse(data)
  if (!validation.success) {
    const issues = validation.error.errors
    const issuesFingerprint = issues
      .map((issue) => issue.path.join('.'))
      .sort()
      .join(', ')
    // eslint-disable-next-line no-console
    console.warn(origin, issuesFingerprint, issues)
    sendErrorToSentry(
      new PosError({
        data: { issues },
        message: `Zod validation error on fields: ${issuesFingerprint} (see data.issues)`,
        severity: 'warning',
        fingerprint: ['runtime-type-error', origin, issuesFingerprint],
      })
    )
  }
}

/** Wrapper for useQuery with some default error handling */
export const useSafeQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseSafeQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const { type, select } = options ?? {}
  const origin = typeof queryKey[0] === 'string' ? queryKey[0] : 'unknown-origin'

  return useQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    async (context) => {
      try {
        return await queryFn(context)
      } catch (error) {
        throw parseAxiosError(error, extractErrorParserOption(error, undefined, options))
      }
    },
    {
      ...options,
      select: useCallback(
        (data: TQueryFnData) => {
          handleDataValidation(origin, data, type)
          // If options.select is undefined, data type TQueryFnData is equal to TData
          return select?.(data) ?? (data as unknown as TData)
        },
        [origin, type, select]
      ),
      onError: (error: TError) => {
        handleError(error)
        if (options?.onError) options.onError(error)
      },
    }
  )
}

export type UseSafeMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey' | 'mutationFn'> &
  SafeQueryOptions<TVariables>

/** Wrapper for the useMutation function with some default error handling */
export const useSafeMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationKey: MutationKey,
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseSafeMutationOptions<TData, TError, TVariables, TContext>
) =>
  useMutation(
    mutationKey,
    async (context) => {
      try {
        return await mutationFn(context)
      } catch (error) {
        throw parseAxiosError(error, extractErrorParserOption(error, context, options))
      }
    },
    {
      ...options,
      onError: async (error: TError, ...props) => {
        handleError(error)
        if (options?.onError) {
          await options.onError(error, ...props)
        }
      },
    }
  )
