import {
  ADDRESS_NOT_IN_LIST_CASE,
  PaymentCreateFormValues,
} from '@/components/Forms/PaymentCreate/types'
import { FeePlan, Me, Payment } from '@/types'
import { onlyDefined } from '@/utils'
import { centsToEuros } from '@/utils/prices'
import { DeepPartial } from 'react-hook-form'
import { FieldPath } from 'react-hook-form/dist/types/path'

type NestedData = { [key: string]: string | NestedData }

const isObject = (data: NestedData | unknown) => data && typeof data === 'object'

/**
 * target: { scoring: { departure: '2024-10-10' } }
 * source: { scoring: { arrival: '2024-12-10' } }
 * Returns: { scoring: { departure: '2024-10-10', arrival: '2024-12-10' } }
 * More examples in related test file.
 */
function deepMerge(target: NestedData, source: NestedData) {
  const output = { ...target }
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = deepMerge(target[key] as NestedData, source[key] as NestedData)
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

/**
 * key: scoring.departure
 * data: {age: 64}
 * Returns: {age: 64, scoring: {departure: ''}}
 */
function addKeyIntoObject(key: string, data: NestedData): NestedData {
  const parts = key.split('.')

  // Key without `.`:
  if (parts.length === 1) {
    return {
      ...data,
      [key]: '',
    }
  }

  // Key containing `.`:
  const firstKeyPart = parts[0]
  const restOfTheKey = parts.slice(1).join('.')
  const subData =
    !data || typeof data[firstKeyPart] === 'string' ? {} : (data[firstKeyPart] as NestedData)

  return {
    ...data,
    [firstKeyPart]: addKeyIntoObject(restOfTheKey, subData),
  }
}

/**
 * Default values are used for initial form data and reset form.
 * It must works in both normal and finalize flow.
 * String keys containing `.` must be converted to nested objects.
 */
export const createDefaultCustomValues = (
  custom_data_template: Me['merchant']['custom_data_template'],
  custom_data?: Payment['custom_data']
) => {
  const templateEmptyData = custom_data_template
    .map(({ key }) => key)
    .reduce((previousData, currentKey) => addKeyIntoObject(currentKey, previousData), {})

  if (custom_data) {
    return deepMerge(templateEmptyData, custom_data as NestedData)
  }

  return templateEmptyData
}

export const readPaymentFormValues: (
  payment: Payment,
  availableFeePlans: FeePlan[],
  me: Me
) => DeepPartial<PaymentCreateFormValues> = (
  {
    customer,
    orders,
    payment_plan,
    purchase_amount,
    custom_data,
    deferred_days,
    deferred_months,
    billing_address,
  },
  availableFeePlans,
  me
) => {
  const feePlan = availableFeePlans.find(
    (plan) =>
      plan.deferred_days === deferred_days &&
      plan.deferred_months === deferred_months &&
      plan.installments_count === payment_plan.length
  )

  const custom = createDefaultCustomValues(me.merchant.custom_data_template, custom_data)

  return {
    phone: onlyDefined({
      number: customer.phone,
    }),
    customer: onlyDefined({
      filledBy: 'merchant',
      email: customer.email,
      firstName: customer.first_name,
      name: customer.last_name,
      addressAutocomplete: ADDRESS_NOT_IN_LIST_CASE,
      addressComplement: billing_address?.line2,
      addressManual: onlyDefined({
        city: billing_address?.city,
        country: billing_address?.country,
        address: billing_address?.line1,
        zipCode: billing_address?.postal_code,
        state: billing_address?.state_province,
        district: billing_address?.county_sublocality,
      }),
    }),
    order: {
      comment: orders[0]?.comment ?? '',
      reference: orders[0]?.merchant_reference ?? '',
    },
    purchase: onlyDefined({
      feePlan,
      formattedAmountInEuros: purchase_amount ? `${centsToEuros(purchase_amount)}` : undefined,
    }),
    custom,
  }
}

type UpdatableFieldPaths = Exclude<
  FieldPath<PaymentCreateFormValues>,
  | `purchase.feePlan.${string}`
  | `terminal${string}`
  | `customer.addressAutocomplete.${string}`
  | 'customer.filledBy'
  | `link${string}`
  | `requirements${string}`
  | `update${string}`
>

/**
 * Map all possible keys with a predicate that says if it is supposed to be disabled in the form when performing an update (Send payment from an API-created payment).
 *
 * This is intended to future-proof the form: the type-system will error if we add a field but don't exclude it from there or implement the predicate.
 *
 * Note that it doesn't work for “dynamic” fields like `custom_data`: for this we need to add the fields in `computeDisabledFieldsForUpdate` below.
 */
const shouldDisableFieldOnUpdate: Record<UpdatableFieldPaths, (payment: Payment) => boolean> = {
  customer: () => false,
  'customer.email': (payment) => Boolean(payment.customer.email),
  'customer.firstName': (payment) => Boolean(payment.customer.first_name),
  'customer.name': (payment) => Boolean(payment.customer.last_name),
  'customer.addressAutocomplete': (payment) => Boolean(payment.billing_address),
  'customer.addressAutocompleteFieldValue': (payment) => Boolean(payment.billing_address),
  'customer.addressComplement': (payment) => Boolean(payment.billing_address),
  'customer.addressManual': () => false,
  'customer.addressManual.address': (payment) => Boolean(payment.billing_address?.line1),
  'customer.addressManual.city': (payment) => Boolean(payment.billing_address?.city),
  'customer.addressManual.country': (payment) => Boolean(payment.billing_address?.country),
  'customer.addressManual.district': (payment) => Boolean(payment.billing_address),
  'customer.addressManual.state': (payment) => Boolean(payment.billing_address),
  'customer.addressManual.zipCode': (payment) => Boolean(payment.billing_address?.postal_code),
  phone: () => false,
  'phone.number': (payment) => Boolean(payment.customer.phone),
  offerFees: () => false,
  'offerFees.offered': (payment) => payment.customer_target_fee === 0,
  'offerFees.offer': () => true,
  order: () => true,
  'order.comment': () => true,
  'order.reference': () => true,
  purchase: () => false,
  'purchase.feePlan': (payment) => Boolean(payment.installments_count),
  'purchase.formattedAmountInEuros': () => true,
  custom: () => false, // Custom fields can normally be editable, but see below : if filled => disabled
}

export const shouldDisabledCustomFieldsOnUpdate: (
  payment: Payment
) => Record<UpdatableFieldPaths, (payment: Payment) => boolean> | Record<string, unknown> = (
  payment
) => {
  const customKeys = Object.keys(payment.custom_data ?? {})
  if (customKeys.length > 0) {
    return customKeys.reduce(
      (acc, customKey) => ({
        ...acc,
        // If a custom field is filled, it can not be edited
        [`custom.${customKey}`]: () => Boolean(payment.custom_data?.[customKey]),
      }),
      {}
    )
  }
  return {}
}
export const computeDisabledFieldsForUpdate = (payment: Payment) =>
  Object.entries({ ...shouldDisableFieldOnUpdate, ...shouldDisabledCustomFieldsOnUpdate(payment) })
    .filter(([, predicate]) => predicate(payment))
    .map(([fieldPath]) => fieldPath)
