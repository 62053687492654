import { App } from '@/components/App'
import '@/index.css'
import '@/polyfill'
import '@alma/react-components/global.css'
import '@alma/react-components/style.css'

import React from 'react'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}
