export interface FeePlan {
  allowed: true
  available_in_pos: true
  customer_fee_fixed: number
  customer_fee_variable: number
  customer_lending_rate: number
  deferred_days: number
  deferred_months: number
  deferred_trigger_limit_days: number | null
  installments_count: number
  kind: 'pos' | 'general'
  max_purchase_amount: number
  merchant: string
  merchant_fee_fixed: number
  merchant_fee_variable: number
  min_purchase_amount: number
  payout_on_acceptance: boolean
}

export interface MaybeNotAllowedFeePlan {
  allowed: boolean
  available_in_pos: boolean
}

export type ApiFeePlan = MaybeNotAllowedFeePlan | FeePlan

export function isAllowedFeePlan(feePlan: ApiFeePlan): feePlan is FeePlan {
  return feePlan.allowed && feePlan.available_in_pos
}

export function isP1X(feePlan: ApiFeePlan): boolean {
  return (
    isAllowedFeePlan(feePlan) &&
    feePlan.installments_count === 1 &&
    feePlan.deferred_days === 0 &&
    feePlan.deferred_months === 0
  )
}
