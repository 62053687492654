import { useMe } from '@/components/AuthenticationManager'
import { StepProps } from '@/components/PaymentsModal/OnlineSteps/Steps/Steps'
import {
  ButtonsAction,
  GreyZone,
  Header,
  ItDoesNotWorkCollapsible,
  List,
  ListItem,
} from '@/components/PaymentsModal/Templates'
import { HorizontalTitle } from '@/components/PaymentsModal/Templates/GreyZone/Title'
import { hasFeatureTag, ActiveTags } from '@/types'
import React, { VoidFunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const ProofOfAddress: VoidFunctionComponent<StepProps> = ({
  amountAndPlan,
  customerName,
  createdDate,
  updatedDate,
  ActionButtons,
}) => {
  const intl = useIntl()
  const me = useMe()
  return (
    <>
      <Header amountAndPlan={amountAndPlan} name={customerName} createdDate={createdDate} />{' '}
      <GreyZone
        progressBarTitle={intl.formatMessage({
          id: 'payment.step.detail.progress.title.verifications',
          defaultMessage: 'Verifications',
          description:
            'Title of the progress bar in the payment detail modal for the verifications steps',
        })}
        progress={40}
      >
        <HorizontalTitle updated={updatedDate}>
          <FormattedMessage
            id="payment.detail.requirementStep.proof_of_address"
            defaultMessage="Proof of address"
            description="Title of the payment requirement step in which we ask for documents to prove the user's address"
          />
        </HorizontalTitle>
        <div>
          <FormattedMessage
            id="payment.step.detail.content.proof_of_address"
            defaultMessage="Your client must provide proof of address (energy or telephone bill or rent receipt) less than 3 months old."
            description="Message to explain that the customer must provide a proof of address by uploading it"
          />
        </div>
      </GreyZone>
      <ItDoesNotWorkCollapsible>
        <List>
          <ListItem>
            <FormattedMessage
              id="it.does.not.work.card.accepted"
              defaultMessage="We accept cards from FR-BE-DE-IT-ES-AT-LU-NL-IE countries"
              description="On the payments details page, in the 'it does no work' collapsible, this explains what bank card we accept"
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="it.does.not.work.card.not.accepted"
              defaultMessage="Make sure the client uploads a document or image in the correct format (.pdf, .jpg or .png) and that it is no larger than 15 MB."
              description="This message explain possible cause of error or problem during the proof of address step"
            />
          </ListItem>
        </List>
        <ButtonsAction>
          {!hasFeatureTag(ActiveTags.cantExitFinalize, me) && (
            <ActionButtons.Restart color="link" />
          )}
          <ActionButtons.Cancel color="link" />
        </ButtonsAction>
      </ItDoesNotWorkCollapsible>
    </>
  )
}
