import { centsToEuros } from '@/utils'
import { useCallback, useRef } from 'react'
import { IntlShape, useIntl } from 'react-intl'

export const emailRegex = /^\S+@\S+\.\S+$/

export const emailPattern = (intl: IntlShape) => ({
  value: emailRegex,
  message: intl.formatMessage({
    id: 'form.invalid.email',
    defaultMessage: 'Invalid email',
    description:
      'Error message displayed in red below the Email field in the customer details section of POS page. It informs the user that the format is incorrect.',
  }),
})

// all lowercase, uppercase characters (with accent) and hyphen excepts special characters
const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ-.'’ ]+$/

export const nameWithOnlySpacesMessage = (intl: IntlShape) =>
  intl.formatMessage({
    id: 'form.validation.field',
    defaultMessage: 'Invalid format',
    description:
      'Error message displayed in red below the field. It informs the user that the format is incorrect.',
  })

export const invalidFirstNameMessage = (intl: IntlShape) =>
  intl.formatMessage({
    id: 'form.invalid.first-name',
    defaultMessage: 'No special characters',
    description:
      'Error message displayed in red below the First Name field in the customer details section of POS page. It informs the user that the format is incorrect.',
  })

export const invalidLastNameMessage = (intl: IntlShape) =>
  intl.formatMessage({
    id: 'form.invalid.last-name',
    defaultMessage: 'No special characters',
    description:
      'Error message displayed in red below the Last Name field in the customer details section of POS page. It informs the user that the format is incorrect.',
  })

export const firstNamePattern = (intl: IntlShape) => ({
  value: nameRegex,
  message: invalidFirstNameMessage(intl),
})

export const lastNamePattern = (intl: IntlShape) => ({
  value: nameRegex,
  message: invalidLastNameMessage(intl),
})

export const nameWithOnlySpaces = (name: string, errorMessage: string) => {
  if (name.length > 0 && name.trim() === '') return errorMessage

  return undefined
}

export const requiredFieldError = (intl: IntlShape) => ({
  value: true,
  message: intl.formatMessage({
    id: 'form.required.field',
    defaultMessage: 'This field is required',
    description:
      'Error message to warn the user that a mandatory field must be filled in before validating a form. It appears below the field in red when you remove the content of the field. It appears for every mandatory field of the customer information section, on POS page.',
  }),
})

export const maxLengthFieldError = (intl: IntlShape) => ({
  value: 250,
  message: intl.formatMessage({
    id: 'form.max.length.field',
    defaultMessage: 'max 250 characters',
    description:
      'Error message displayed when too many characters were filled in any of the fields of the payment form',
  }),
})

export const patternFieldError = (intl: IntlShape, regex: RegExp, desc?: string) => ({
  value: regex,
  message: desc
    ? intl.formatMessage(
        {
          id: 'form.pattern.fieldWithDesc',
          defaultMessage: 'Invalid format: {desc}',
          description:
            'Error message displayed in red below the custom field added by the merchant (to match an internal reference for instance) when the input filled in that filled does not match the format defined in the dashboard (only numbers, 6 characters for instance). If a description is filled, it is displayed in the error',
        },
        { desc }
      )
    : intl.formatMessage({
        id: 'form.pattern.field',
        defaultMessage: 'Invalid format',
        description:
          'Error message displayed in red below the custom field added by the merchant (to match an internal reference for instance) when the input filled in that filled does not match the format defined in the dashboard (only numbers, 6 characters for instance)',
      }),
})

export const useValidatePhoneNumber = () => {
  const isInvalid = useRef(false)
  const intl = useIntl()

  const rulesValidator = useCallback(
    (value: unknown) =>
      value && isInvalid.current
        ? intl.formatMessage({
            id: 'form.number.invalid',
            defaultMessage: 'Invalid phone number',
            description:
              'Error message displayed in red below the phone number field in the customer details section of POS page. It informs the user that the format is incorrect.',
          })
        : true,
    [intl]
  )

  const onPhoneNumberFieldInvalid = useCallback((invalid: boolean) => {
    isInvalid.current = invalid
  }, [])

  return [rulesValidator, onPhoneNumberFieldInvalid] as const
}

export const amountIntervalErrorMessage = (
  intl: IntlShape,
  [minInCents, maxInCents]: [number, number]
) =>
  intl.formatMessage(
    {
      id: 'home.purchase.error.notEligible',
      defaultMessage: 'The amount must be between {minValue} € and {maxValue} €',
      description:
        'Error message about the amount of the purchase. It appears in red below the purchase amount field when you enter the wrong value in the field.',
    },
    {
      minValue: centsToEuros(minInCents),
      maxValue: centsToEuros(maxInCents),
    }
  )

export const isInInterval = (amount: number, [min, max]: [number, number]) =>
  min <= amount && amount <= max

export const merchantCannotCreatePaymentMessage = (intl: IntlShape) =>
  intl.formatMessage({
    id: 'home.purchase.error.merchantCannotCreatePayment',
    defaultMessage:
      'Your merchant account is under activation by our services, please contact support team for more informations.',
    description:
      'Error message about the merchant account activation, if not activated by our services the merchant cannot create payments.',
  })
